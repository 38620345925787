import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getEducatorbyClusterId, educatorFollowStatus } from '../Slices/CoachmiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as TamilIcon } from '../assets/images/tamilIcon.svg';
import { ReactComponent as EnglishIcon } from '../assets/images/englishIcon.svg';
import { Image, Modal } from "antd";
import BackIcon from '../assets/images/back_icon.svg';
import '../styles/AllEducators.scss';
import { renderFollowStatusFunction } from "../utlis/HelperFunctions.js";
import Loading from '../Component/Loading';
import EducatorIcon from '../assets/images/user_icon.svg'


const AllEducators = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clusterId } = useParams();
  const { educatorClusterData, educatorFollowData,isEducatorLoading,isLoading } = useSelector((state) => state.Coachmi);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [educatorDetails, setEducatorDetails] = useState({});


  useEffect(() => {
    dispatch(getEducatorbyClusterId({ clusterId: clusterId, isLimitedData: false }))
  }, [])

  const goBack = () => {
    navigate('/home/coachmi')
  }

  const handleFollow = (e) => {
    const { id, following, allowUnfollwed = false } = e || {};
    if (following && !allowUnfollwed) {
      setEducatorDetails(e);
      setIsConfirmModalVisible(true);
    } else {
      dispatch(educatorFollowStatus({ id, clusterId, isLimitedData: true }));
      setEducatorDetails({});
    }
  };

  const handleOk = () => {
    setIsConfirmModalVisible(false);
    handleFollow({ ...educatorDetails, allowUnfollwed: true });
  };

  return (
    <>
     {
            isLoading ? (
                <div className='loading-container'>
                <Loading></Loading>
                </div>
            ) : (
      <div className="overAll-course-contaniner">
        <div className='course-header'>
          <Image preview={false} src={BackIcon} 
          className="back-icon"
          //  onClick={() => goBack()} 
          onClick={() => navigate(-1)}
           />
          <p>Educators</p>
        </div>

        <div className="free-classes-container">
          <div className="educators">
            {educatorClusterData?.map((educator, index) => (
              <div className="educator-card" key={index}>
                <img
                  src={educator['attachmentUrl'] || EducatorIcon}
                  alt={educator['educatorName']}
                  className="educator-image"
                />
                <div className="educator-text">
                  <span className="educator-name">{educator['educatorName']}</span>
                  <span className="educator-follow">
                    {educator.followingCount} followers
                  </span>
                  {renderFollowStatusFunction(educator, handleFollow, isEducatorLoading)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
   <Modal
     title="Confirmation"
     visible={isConfirmModalVisible}
     onOk={handleOk}
     onCancel={()=> setIsConfirmModalVisible(false)}
     okText="Yes"
     cancelText="No">
     <p>Are you sure you want to unfollow this educator?</p>
    </Modal>
    </>
  );
}

export default AllEducators;
