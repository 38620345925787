import React, { useEffect, useState } from "react";
import '../styles/ClusterGroupCourse.scss';
import subscriptImg from '../assets/images/subscriptImg.svg';
import { Input, Select, Image, Button, Tooltip } from "antd";
import { getAllCoursesByClusterGroup, getClusterGroupDetailsById, getSubscriptionPlan, removesubscription } from "../Slices/CoachmiSlice";
import { useDispatch, useSelector } from "react-redux";
// import { addBreadCrumbData, getCoachmiBreadCrumb } from '../Slices/HomePageSlice';
import vectorImg from '../assets/images/courseVector.svg';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import BackIcon from '../assets/images/back_icon.svg';
import { ECoachmiBreadCrumbData } from "../utlis/enum";
import { ReactComponent as TamilIcon } from '../assets/images/tamilIcon.svg'
import { ReactComponent as EnglishIcon } from '../assets/images/englishIcon.svg'
import { ECoachmiSubscription } from "../utlis/enum.js";
import useBackNavigation from '../hooks/useBackNavigation';
import { getLeastMonthsSubscriptionHelper, setLocalStorageItemHelper, CoursePricingHelper, renderVerticalLineHelper } from "../utlis/HelperFunctions.js";
import Loading from "../Component/Loading.js";
import { defaultCourseThumbnail } from "../utlis/variable.js";

const ClusterGroupCourse = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { slugId } = location.state || {};
  const navigate = useNavigate();
  const goBack = useBackNavigation();
  const clusterGroupMappingId = params?.clusterGroupMappingId;

  useEffect(() => {
    dispatch(getClusterGroupDetailsById({ id: clusterGroupMappingId }))
    dispatch(getAllCoursesByClusterGroup({ id: clusterGroupMappingId }));
    dispatch(removesubscription());
    dispatch(getSubscriptionPlan({ type: ECoachmiSubscription.CLUSTER_GROUP, id: clusterGroupMappingId, queryParams: { isLeastPrice: true } }));
  }, [dispatch, clusterGroupMappingId]);

  useEffect(() => {
    // let payload = {
    //   type: ECoachmiBreadCrumbData.CLUSTER_GROUP,
    //   slugId: slugId
    // }
    // dispatch(getCoachmiBreadCrumb(payload))
  }, []);

  const { ClusterGroupCourseData, isLoading, clusterGroupDetails, subscriptionData } = useSelector((state) => state.Coachmi);

  const handleSubscribeClick = () => {
    setLocalStorageItemHelper('subscriptionGoBackPath', location.pathname)
    navigate(`/home/subscriptionPlan/${clusterGroupMappingId}/?type=${ECoachmiSubscription.CLUSTER_GROUP}`);
  };

  const leastSubscription = getLeastMonthsSubscriptionHelper(subscriptionData?.planPricing);

  return (
    <>
      <div className="cluster-group-course">
        <div className="content-header">
          <Image
            preview={false}
            src={BackIcon}
            className="back-icon"
            // onClick={() => goBack('/home/coachmi')}
            onClick={() => navigate(-1)}
          />
          <p>{clusterGroupDetails?.clusterGroupName}</p>
        </div>
        {
          (subscriptionData['planPricing']?.length > 0 && !subscriptionData['isValidSubscription']) && (
            <div className="cluster-group-header">
              <div className="cluster-image">
                <Image className="left-vector" preview={false} src={vectorImg}></Image>
                <Image preview={false} src={subscriptImg}></Image>
                <Image className="right-vector" preview={false} src={vectorImg}></Image>
                <p>Subscribe {subscriptionData?.name} Start Learn start  <span>{leastSubscription?.price && '₹' + leastSubscription?.price}</span></p>
              </div>
              <Button className="cluster-group-subscribe" onClick={() => handleSubscribeClick()}>Subscribe</Button>
            </div>
          )}
        <div className="courses-grid" >
          {ClusterGroupCourseData.map((course, index) => {
            const hasDuration = Boolean(course['duration']);
            const hasTests = course['testCount'] > 0;
            const hasDocuments = course['documentCount'] > 0;
            return (
              <div className="course-card" key={index} onClick={() => navigate(`/home/course/${clusterGroupMappingId}/${course?.courseMappingId}`, { state: { slugId: course?.slugId } })}>
                {
                  course.courseThumbnailUrl ? (
                    <img
                      src={course.courseThumbnailUrl}
                      alt={course.courseName}
                      className="course-image"
                    />
                  ) : (
                    <div className="course-image">
                      <img
                        src={defaultCourseThumbnail}
                        alt={defaultCourseThumbnail}
                        className="course-image"
                      />
                    </div>
                  )
                }
                <div className="course-tag">
                  {course.languageId == 1 ? (
                    <TamilIcon />
                  ) : (
                    <EnglishIcon />
                  )}
                  <span className="course-type">Recorded </span>
                </div>
                <Tooltip title={course.courseName}>
                  <p>{course?.courseName}</p>
                </Tooltip>
                <div className="course-details">
                  {hasDuration && (
                    <>
                      <p className="courseHours">{course['duration']}</p>
                      {renderVerticalLineHelper(hasTests || hasDocuments)}
                    </>
                  )}

                  {hasTests && (
                    <>
                      <p className="courseTests">{course['testCount']} Test Series</p>
                      {renderVerticalLineHelper(hasDocuments)}
                    </>
                  )}

                  {hasDocuments && (
                    <p className="courseDocs">{course['documentCount']} Documents</p>
                  )}
                </div>
                {CoursePricingHelper(course)}
              </div>
            )
          }
          )}
        </div>
      </div>
    </>

  );
};

export default ClusterGroupCourse;