import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image, Button, Empty, Tooltip, Modal } from "antd";
import BackIcon from '../assets/images/back_icon.svg'
import { useNavigate, useParams, useLocation } from "react-router-dom";
import '../styles/Content.scss'
import EducatorsIcon from "../assets/images/educators.svg";
import PdfIcon from '../assets/images/PDF File.svg'
import DownloadIcon from '../assets/images/Download.svg'
import TamilIcon from "../assets/images/tamilIcon.svg";
import EnglishIcon from "../assets/images/englishIcon.svg";
import { getContentDetails, getContentEducator, getStudyMaterialsById, getAllRecommendedTest, educatorContentFollow, getContentVideoUrl, removesubscription, getAllContentDetails, getContentUpNext, downloadAttachFile } from "../Slices/CoachmiSlice";
import { ECoachmiBreadCrumbData, ECoachmiSubscription, EContentType } from "../utlis/enum";
// import { getCoachmiBreadCrumb } from "../Slices/HomePageSlice";
import UserIcon from '../assets/images/user_icon.svg'
import { handleToastHelper, setLocalStorageItemHelper } from "../utlis/HelperFunctions.js";
import Loading from '../Component/Loading';
import ComponentLoading from "../Component/ComponentLoading.js";
import { FaPlayCircle, FaPauseCircle } from 'react-icons/fa';
import { defaultContentThumbnail } from "../utlis/variable";

const Content = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { subTopicMappingId } = useParams()
  const { contentData, studyMaterialsData, recommendedTestData, contentEducatorsData, pricePlanData, contentVideo, contentDetailsData, subTopicDetailsData, upNextData, isLoading } = useSelector((state) => state.Coachmi);
  const location = useLocation();
  const { slugId } = location.state || {};
  const { clusterId } = useParams();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [educatorDetails, setEducatorDetails] = useState({});
  const [playingIndex, setPlayingIndex] = useState(null);
  const [videoUrls, setVideoUrls] = useState({});
  const videoRefs = useRef({});
  const [activeContentId, setActiveContentId] = useState(null);


  console.log("kklklklk,", subTopicDetailsData, contentDetailsData);
  useEffect(() => {
    // let payload = {
    //   type: ECoachmiBreadCrumbData.CONTENT,
    //   slugId: slugId,
    // }
    dispatch(removesubscription());
    // dispatch(getCoachmiBreadCrumb(payload))
    dispatch(getAllContentDetails(subTopicMappingId))
  }, []);

  // useEffect(() => {
  //   dispatch(getContentVideoUrl({ contentAttachmentId: contentData?.contentAttachmentId, type: EContentType.COACHMI_CONTENT }))
  // }, [contentData])

  const goBack = () => {
    navigate('/home/coachmi')
  }

  // const handleSubscribeClick = () => {
  //   setLocalStorageItemHelper('subscriptionGoBackPath', location.pathname)
  // };

  const renderContentFollowStatusFunction = (educator, paramFunction, isLoading) => {

    const className = educator['following'] ? 'follow following' : 'follow';
    const content = educator['following'] ? 'Following' : 'Follow';

    return (
      <>
        <Button className={className} onClick={() => paramFunction(educator)}>
          {isLoading ? <ComponentLoading /> : content}
        </Button>
      </>
    )
  };

  const handleFollow = (e) => {
    const { id, following, allowUnfollwed = false } = e || {};
    if (following && !allowUnfollwed) {
      setEducatorDetails(e);
      setIsConfirmModalVisible(true);
    } else {
      dispatch(educatorContentFollow({ id, contentMappingId: contentDetailsData.id, clusterId }))
      setEducatorDetails({});
    }
  };

  const handleOk = () => {
    setIsConfirmModalVisible(false);
    handleFollow({ ...educatorDetails, allowUnfollwed: true });
  };

  const takeTest = (testId) => {
    navigate(`/home/previousYearTestInstruction/${testId}`)
  }


  const navigateContentScreen = (item, index) => {
    setPlayingIndex(index === playingIndex ? null : index);
  };


  const handleItemClick = async (item, index) => {
    await dispatch(getContentUpNext(item.id)).unwrap().then(async (res) => {
      await getCloudFrontUrl(res?.data?.videoAttachment[0]?.['attachmentMappingId'], true)
    })
    setPlayingIndex(index === playingIndex ? null : index);
    setActiveContentId(item.id)
  };

  const downloadFile = async (id, downloadModuleId) => {
    try {
      let payload = {
        id: id,
        downloadModuleId: downloadModuleId
      }
      const response = await dispatch(downloadAttachFile(payload)).unwrap().then((res) => {
        handleToastHelper(res)
      })
    } catch (error) {
      handleToastHelper(error)
      console.error('Error in download:', error);
    }
  }


  const getCloudFrontUrl = async (id) => {
    try {
      const response = await dispatch(getContentVideoUrl({ contentAttachmentId: id, type: EContentType.COACHMI_CONTENT })).unwrap();
      const videoUrl = response['data'] || {};
      setVideoUrls((prevUrls) => ({
        ...prevUrls,
        [id]: videoUrl,
      }));

      if (videoRefs.current[id]) {
        videoRefs.current[id].load();
        videoRefs.current[id].play();
      }
    } catch (error) {
      handleToastHelper(error);
    }
  };

  return (
    <>
      {
        isLoading ? (
          <div className='loading-container'>
            <Loading></Loading>
          </div>
        ) : (
          <div className="content-overAll-container">
            <div className="content-header">
              <Image
                preview={false}
                src={BackIcon}
                className="back-icon"
                // onClick={() => goBack()}
                onClick={() => navigate(-1)}
              />
              <p>{subTopicDetailsData?.contentName}</p>
              <p>{subTopicDetailsData.subTopicName}</p>
            </div>
            {/* {(subscriptionData['planPricing']?.length > 0 && !subscriptionData['isValidSubscription']) && (
              <div className="price-card">
                <div className="price-info">
                  <p className="current-price">
                    ₹ {pricePlanData?.currentPrice} only
                  </p>
                  <div className="discount-info">
                    <p className="original-price">₹ {pricePlanData?.originalPrice}</p>
                    <p className="discount">{pricePlanData?.discount} % off</p>
                  </div>
                </div>
                <button className="buy-button" onClick={() => handleSubscribeClick()}>
                  Buy
                </button>
              </div>
            )} */}
            <div className="content-container">
              <div className="content-wrapper">
                {contentDetailsData?.videoAttachment?.map((item, index) => {
                  const videoUrl = videoUrls[item['attachmentMappingId']];
                  const thumbnailUrl = item['thumbnailUrl'] || defaultContentThumbnail;
                  return (
                    <div className="content-video-wrapper content-video content-video-wrapper-con" onClick={() => getCloudFrontUrl(item['attachmentMappingId'], true)}>
                      {videoUrl ? (
                        <video height="370" controls
                          ref={(el) => (videoRefs.current[item['attachmentMappingId']] = el)}
                        >
                          <source src={videoUrls[item['attachmentMappingId']]} type="video/mp4" />
                        </video>
                      )
                        : (
                          <div className={item['thumbnailUrl'] != null ? 'content-image-section' : ''}>
                            <Image
                              src={thumbnailUrl}
                              preview={false}
                              className="content-thumnail-wrapper"
                            />
                          </div>
                        )}
                    </div>
                  )
                }
                )}

                <div className="content-overview-container">
                  <div className="educator-card">
                    <p className="educator-title">Educator</p>
                    <div className="scroll-bar">
                      {contentDetailsData?.educatorDetails && contentDetailsData?.educatorDetails?.length > 0 ? (
                        contentDetailsData?.educatorDetails?.map((educator, index) => (
                          <div key={index} className="educator-info">
                            <div className="educator-image">
                              <Image
                                preview={false}
                                src={
                                  educator.profile
                                    ? educator.profile
                                    : UserIcon
                                }
                                alt="educator image"
                              />
                            </div>
                            <div className="educator-details">
                              <div className="educator-details-wrapper">
                                <p className="educator-name">
                                  {educator.name}
                                </p>
                                <p className="educator-followers">
                                  {educator.followingCount} Followers
                                </p>
                              </div>
                              {renderContentFollowStatusFunction(educator, handleFollow, isLoading)}
                            </div>
                          </div>
                        ))
                      ) : (
                        <Empty description="No educators found" />
                      )}
                    </div>
                  </div>

                  <div className="study-material">
                    <p className="study-material-title">Study Material</p>
                    <div className="pdf-scroll">


                      <div className="pdf-container">
                        {contentDetailsData?.['notesDetails'] && contentDetailsData?.['notesDetails'].length > 0 ? (
                          contentDetailsData?.['notesDetails']?.map((material, index) => (
                            <div key={index} className="pdf">
                              <div className="pdf-content-wrapper">
                                <Image preview={false} src={PdfIcon} />
                                <p className="pdf-title">{material.name}</p>
                              </div>

                              {contentDetailsData?.isValidSubscription ? (
                                material?.isDownloaded ? (
                                  <div className="download-icon">
                                    <span>Downloaded</span>
                                  </div>
                                ) : (
                                  <div className="download-icon">
                                    <div onClick={() => downloadFile(material.id, material.downloadModuleId)}>
                                      <Image preview={false} src={DownloadIcon} />
                                    </div>
                                  </div>
                                )
                              ) : <></>}
                            </div>
                          ))
                        ) : (
                          <Empty description="No study materials found" />
                        )}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="content-about">
                <div className="content-type-container">
                  <p className="content-type">{contentData?.topicName}</p>
                  <p className="content-type">{subTopicDetailsData?.videoType}</p>
                  <Image
                    preview={false}
                    src={contentData?.languageTypeId == 1 ? TamilIcon : EnglishIcon}
                    className="language-icon"
                  />
                </div>
                <p className="content-title">{contentDetailsData?.contentName}</p>
                <p className="content-description">
                  {contentDetailsData?.description}
                </p>
              </div>

              <div className="container">
                <p className="upnexttext">Up Next</p>
                <div className="scrollview">
                  {subTopicDetailsData['contentList']?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleItemClick(item, index)}
                      className={`item-container ${activeContentId === item.id ? 'active' : ''}`}
                    >
                      <div className="playicon-container">
                        {playingIndex === index ? (
                          <FaPauseCircle className="iconStyle" size={20} />
                        ) : (
                          <FaPlayCircle className="iconStyle" size={20} />
                        )}
                      </div>
                      <div className="content-info">
                        <span className="content-name">{item?.name}</span>
                        <span className="content-duration">{item?.duration}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="content-recommended-test">
                <p className="recommended-test">Recommended Test For {contentDetailsData?.contentName}</p>
                <div className="test-cards">
                  {contentDetailsData?.recommendedTestDetails?.map((test) => (
                    <div key={test.id} className="test-card">
                      <div className="test-info">
                        <div className="test-type">
                          <Tooltip title={test.examGroupName}>
                            <span className="test-category">
                              {" "}
                              {test.examGroupName}
                            </span>
                          </Tooltip>
                        </div>
                        <Tooltip title={test.testName}>
                          <div className="test-title">{test.testName}</div>
                        </Tooltip>
                        <div className="test-details">
                          <span>{test.numberOfQuestions} Qs.</span>
                          <span>| {test.totalMarks} Marks</span>
                          <span>| {test.timeLimit} mins</span>
                        </div>
                      </div>
                      {contentDetailsData?.isValidSubscription ? (
                        <Button
                          className="take-test-button"
                          onClick={() => takeTest(test?.testId)}
                        >
                          Take Test
                        </Button>
                      ) : (
                        <span className="paid-text">purchase</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )
      }
      <Modal
        title="Confirmation"
        visible={isConfirmModalVisible}
        onOk={handleOk}
        onCancel={() => setIsConfirmModalVisible(false)}
        okText="Yes"
        cancelText="No">
        <p>Are you sure you want to unfollow this educator?</p>
      </Modal>

    </>
  );
}

export default Content
