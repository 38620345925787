import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Select, Image, Tooltip } from "antd";
import { getAllTopicByCourseMappingId, getTopicDetailsById, getAllSubTopicByTopicMappingId, resetSubTopicDetails } from "../Slices/CoachmiSlice";
// import { getCoachmiBreadCrumb } from "../Slices/HomePageSlice";
import { ECoachmiBreadCrumbData } from "../utlis/enum";
import BackIcon from '../assets/images/back_icon.svg';
import SubTopicIcon from '../assets/images/sub_topic_icon.svg';
import ArrowIcon from '../assets/images/contentAarrow.svg';
import useBackNavigation from '../hooks/useBackNavigation';
import '../styles/ContentList.scss';
import Loading from "../Component/Loading.js";
import NoDataComponent from "../utlis/NoDataComponent.js"
import { SubTopicNoDataContentHelper } from "../utlis/HelperFunctions.js";

const { Option } = Select;

const TopicSelector = ({ contentFilterData, topicMapId, selectedTopic, handleTopicChange }) => {
  return (
    <Select
      className="capitalize"
      id="topicSelector"
      name="topicSelector"
      showSearch
      filterOption={(input, option) =>
        (option['children']['props']['children']['props']['children']?.toLowerCase() ?? '').includes(input.toLowerCase())
      }
      placeholder="Select Topic"
      onChange={handleTopicChange}
      defaultValue={topicMapId}
      value={selectedTopic}
    >
      {contentFilterData?.map((topic) => (
        <Option key={topic['topicMapId']} value={topic['topicMapId']}>
          <Tooltip title={topic['value']}>
            <span className="capitalize">{topic['value']}</span>
          </Tooltip>
        </Option>
      ))}
    </Select>
  );
};

const ContentList = () => {

  const dispatch = useDispatch();
  const { clusterId, clusterGroupMappingId, courseMappingId, topicMapId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { slugId } = location.state || {};
  const goBack = useBackNavigation();
  const [goBackPath] = useState(clusterGroupMappingId ? `/home/Course/${clusterGroupMappingId}/${courseMappingId}` : clusterId ? `/home/Course/${clusterId}/${clusterGroupMappingId}/${courseMappingId}` : `/home/Course/${courseMappingId}`);

  const { subTopicDetails, ContentFilterData, topicDetails, subTopicLoading } = useSelector((state) => state.Coachmi);
  const [selectedTopic, setSelectedTopic] = useState(null);


  useEffect(() => {
    // const payload = {
    //   type: ECoachmiBreadCrumbData.TOPIC,
    //   slugId: slugId,
    // };
    dispatch(resetSubTopicDetails());
    // dispatch(getCoachmiBreadCrumb(payload));
    dispatch(getTopicDetailsById({ id: topicMapId }));
    dispatch(getAllTopicByCourseMappingId({ id: courseMappingId })).unwrap().then((response) => {
      dispatch(getAllSubTopicByTopicMappingId({ id: topicMapId }));
    });
  }, [dispatch, courseMappingId, slugId]);


  const handleTopicChange = (value) => {
    setSelectedTopic(value);
    if (value) {
      dispatch(resetSubTopicDetails());
      dispatch(getTopicDetailsById({ id: value }));
      dispatch(getAllSubTopicByTopicMappingId({ id: value }));
    }
  };

  const navigateToUpNextScreen = (id) => {
    console.log(id, 'id.....');
    navigate(`/home/content/${id}`)
  };

  useEffect(() => {
    setSelectedTopic(Number(topicMapId));
  }, [Number(topicMapId)]);

  return (
    <>
      <div className="course-content-screen">
        <div className="content-header">
          <Image
            preview={false}
            src={BackIcon}
            className="back-icon"
            onClick={() => navigate(-1)}
          />
          <p className="capitalize">{topicDetails['name']}</p>
        </div>

        <div className="content-filter">
          <div className='form-control'>
            <span className="filter-title"> Topic: </span>
            {
              ContentFilterData?.length > 0 && (
                <TopicSelector
                  contentFilterData={ContentFilterData}
                  topicMapId={topicMapId}
                  selectedTopic={selectedTopic}
                  handleTopicChange={handleTopicChange}
                />
              )
            }
          </div>

        </div>

        {
          !subTopicLoading ? (
            <div className="courses-grid">
              {
                subTopicDetails?.length > 0 ?
                  (
                    <div className="content-scroll content-list">
                      {subTopicDetails?.map((item, index) => (
                        <div
                          className="content-item"
                          onClick={() => { navigateToUpNextScreen(item['id']) }}
                          key={index.toString()}
                        >
                          <div className="content-icon">
                            <img src={SubTopicIcon} alt="" />
                          </div>
                          <div className="content-details">
                            <p className="content-title">{item['name']}</p>
                            <p className="content-subtopic">
                              {item['counts']}
                            </p>
                          </div>
                          <div className="content-arrow">
                            <img src={ArrowIcon} alt="" />
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                  : (
                    <div className="no-data-found-wrapper">
                      <NoDataComponent content={SubTopicNoDataContentHelper()} />
                    </div>
                  )
              }
            </div>
          ) : (
            <Loading />
          )
        }

      </div>
    </>

  );
};

export default ContentList;



