import React, { useEffect, useState } from "react";
import '../styles/Course.scss';
import { Input, Select, Image, Button, Modal } from "antd";
import { getCourse,getFreeClass,getContentCount, getAllCoursesByClusterGroup, getTopicDetailsById, getSubscriptionPlan, removesubscription } from "../Slices/CoachmiSlice";
import { useDispatch, useSelector } from "react-redux";
import liveClassImg from '../assets/images/hrsImg.svg';
import testSeriesIMg from '../assets/images/testSeriImg.svg';
import languageImg from '../assets/images/laungauageImg.svg';
import shareIcon from '../assets/images/shareIcon.svg'
import ShareLink from "../Component/ShareLink";
import contentImgIcon from '../assets/images/contentImg.svg';
import ArrowIcon from '../assets/images/contentAarrow.svg';
import { useParams,useNavigate,useLocation } from 'react-router-dom';
import BackIcon from '../assets/images/back_icon.svg';
// import { getCoachmiBreadCrumb } from "../Slices/HomePageSlice";
// import { ECoachmiBreadCrumbData } from "../utlis/enum";
import {ReactComponent as TamilIcon} from '../assets/images/tamilIcon.svg'
import {ReactComponent as EnglishIcon} from '../assets/images/englishIcon.svg'
import { ECoachmiSubscription } from "../utlis/enum.js";
import useBackNavigation from '../hooks/useBackNavigation';
import { setLocalStorageItemHelper } from "../utlis/HelperFunctions.js";
import Loading from '../Component/Loading';
import { defaultCourseThumbnail } from "../utlis/variable.js";


const Course = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clusterId, clusterGroupMappingId, courseMappingId } = useParams();
    const location = useLocation();
    const { slugId } = location.state || {};

    const [goBackPath] = useState(
      clusterGroupMappingId
        ? `/home/coachmi/clusterGroupCourse/${clusterGroupMappingId}`
        : courseMappingId
        ? `/home/allCourse/${courseMappingId}`
        : '/home/coachmi'
    );

  const [nextPath] = useState(clusterGroupMappingId ? `/home/contentList/${clusterGroupMappingId}/${courseMappingId}` : `/home/contentList/${courseMappingId}`);

  const goBack = useBackNavigation();

  useEffect(() => {
    // let payload = {
    //         type:ECoachmiBreadCrumbData.COURSE,
    //         slugId:slugId,
    // }
    // dispatch(getCoachmiBreadCrumb(payload))
    dispatch(getCourse({ id: courseMappingId }));
    dispatch(getFreeClass())
    dispatch(getContentCount({ id: courseMappingId }));
    dispatch(removesubscription());
    dispatch(getSubscriptionPlan({ type: ECoachmiSubscription.COURSE, id: courseMappingId, queryParams: { isLeastPrice: true } }));
  }, [dispatch, courseMappingId]);

  const { CourseData, isLoading, FreeClassData, contentCountData, subscriptionData,isCourseLoading } = useSelector((state) => state.Coachmi);
  const [visible, setVisible] = useState(false);
  const currentURL = `http://localhost:3000/home/course/${courseMappingId}`;
  const encodedURL = encodeURIComponent(currentURL);


  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const navigateContentListPage = ({ topicMappingId }) => {
    const path = clusterGroupMappingId ? `/home/contentList/${clusterGroupMappingId}/${courseMappingId}/${topicMappingId}` : clusterId ? `/home/contentList/${clusterId}/${clusterGroupMappingId}/${courseMappingId}/${topicMappingId}` : `/home/contentList/${courseMappingId}/${topicMappingId}`;
    goBack(path);
  };

  const handleSubscribeClick = () => {
    setLocalStorageItemHelper('subscriptionGoBackPath', location.pathname)
    navigate(`/home/subscriptionPlan/${courseMappingId}/?type=${ECoachmiSubscription.COURSE}`);
  };

  return (
    <>
    {
            isCourseLoading ? (
                <div className='loading-container'>
                <Loading></Loading>
                </div>
            ) : (
        <div className="course-container">
        <div className="content-header">
          <Image
            preview={false}
            src={BackIcon}
            className="back-icon"
            // onClick={goToContentList}
            onClick={() => navigate(-1)}
          />
          <p>{CourseData.courseName}</p>
        </div>
        <div className="course-cluster-container">
          <div className="course-title">
            <div className="course-image-container">
              <img
                src={CourseData['courseThumbnail'] || defaultCourseThumbnail}
                alt={CourseData['courseName']}
                className="course-image"
              />
            </div>
            <div className="course-details-container">
              <img
                src={shareIcon}
                alt="share-icon"
                className="share-icon"
                onClick={showModal}
              />
              <ShareLink
                visible={visible}
                onCancel={handleCancel}
                data={courseMappingId}
                currentURL={currentURL}
                encodedURL={encodedURL}
              />
              <div className="course-tag">
                <p>Recorded</p>
                <span>
                  {CourseData.languageId == 1 ? (
                    <p>
                      <TamilIcon />
                    </p>
                  ) : (
                    <p>
                      <EnglishIcon />
                    </p>
                  )}
                </span>
              </div>
              <p className="course-topic">{CourseData.courseName}</p>
              <p className="course-description">{CourseData.about}</p>
            </div>
          </div>
          <div className="course-info-container">
            <div className="course-info">
              {/* <div className="course-info-item">
                <img src={liveClassImg} alt="Live Classes" />
                <p>
                  Classes:
                  <br />{" "}
                  <span className="span-tag">
                    {CourseData['duration']}
                  </span>
                </p>
              </div> */}
              <div className="course-info-item">
                <img src={testSeriesIMg} alt="Test Series" />
                <p>
                  Test Series: <br />
                  <span className="span-tag">
                    {CourseData['testSeriesCount']}
                  </span>
                </p>
              </div>
              <div className="course-info-item">
                <span className="languageImg">
                  {CourseData.languageId == 1 ? (
                    <p>
                      <TamilIcon />
                    </p>
                  ) : (
                    <p>
                      <EnglishIcon />
                    </p>
                  )}
                </span>
                {/* </div> */}
                <p>
                  Language:
                  <br />{" "}
                  <span className="span-tag">{CourseData['language']}</span>
                </p>
              </div>
            </div>
            { (subscriptionData['planPricing']?.length > 0 && !subscriptionData['isValidSubscription']) && (
              <Button type="primary" className="subscription-button" onClick={handleSubscribeClick}>
                View Subscription
              </Button>
            )}
          </div>
        </div>
        {/* <div className="free-classes">
            <p>Free Classes</p>
            <div className="classes-list">
              {FreeClassData.map((classItem, index) => (
                <div className="class-card" key={index.toString()}>
                     {
                classItem.image ? (
                  <img
                  src={classItem.image}
                  alt={classItem.freeClassName}
                    className="class-image"
                  />
                ) : (
                  <div className="class-image class-image-default"></div>
                )
              }
                  <div className="class-details">
                    <p className="freeclass-title">{classItem.freeClassName}</p>
                    <p className="date-time">
                      {classItem.freeClassDate} | {classItem.freeClassTime}
                    </p>
                    <p className="free-class-instructor">
                      by {classItem.educator}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div> */}

        <div className="content-list">
          <p>Content</p>
          <div className="content-scroll">
            {contentCountData.map((item, index) => (
              <div
                className="content-item"
                onClick={() => { navigateContentListPage(item) }}
                key={index.toString()}
              >
                <div className="content-icon">
                  <img src={contentImgIcon} alt="" />
                </div>
                <div className="content-details">
                  <p className="content-title">{item?.topicName}</p>
                  <p className="content-subtopic">
                    {item?.subTopicCount} Subtopics
                  </p>
                </div>
                <div className="content-arrow">
                  <img src={ArrowIcon} alt="" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
            )
    }
      
    </>
  );
};

export default Course;