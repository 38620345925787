import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMyDownloads } from "../Slices/CoachmiSlice.js";
import { useNavigate } from "react-router-dom";
import BackIcon from "../assets/images/back_icon.svg";
import Loading from "../Component/Loading.js";
import NoDataComponent from "../utlis/NoDataComponent.js";
import { Image } from "antd";
import { NoDataDownloadsHelper } from "../utlis/HelperFunctions";
import { ReactComponent as PDFIcon } from '../assets/images/PDF File.svg'
import '.././styles/AllMyDownloads.scss'

const AllMyDownloads = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [downloadsData, setDownloadsData] = useState([]);
  const { myDownloadsData, isLoading } = useSelector((state) => state.Coachmi);

  useEffect(() => {
    dispatch(getAllMyDownloads());
  }, []);

  useEffect(() => {
    setDownloadsData(myDownloadsData);
  }, [myDownloadsData]);

  const goBack = () => {
    navigate("/home/coachmi");
  };

  const openPdf = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('PDF URL is not available');
    }
  };
  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <Loading></Loading>
        </div>
      ) : (
        <div className="overall-myDownloads-container">
          <div className="myDownloads-header">
            <Image
              preview={false}
              src={BackIcon}
              className="back-icon"
              // onClick={() => goBack()}
              onClick={() => navigate(-1)}
               />
            <p>My Downloads</p>
          </div>

          {downloadsData?.length > 0 ? (
            <div className="myDownloads-container">
              <div className="download-grid">
                {downloadsData.map((item) => (
                  <div key={item?.id} className="download-item" onClick={() => openPdf(item?.attachmentUrl)}>
                    <PDFIcon className="pdf-icon" />
                    <p className="pdf-title">{item?.attachmentName}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="no-data-found-wrapper course-wrapper">
              <NoDataComponent content={NoDataDownloadsHelper()} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AllMyDownloads;
