import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import {getEncryptedLocalStorage} from "../utlis/HelperFunctions"

export const beginTest = createAsyncThunk('TestPage/BeginTest', async (payload, thunk) => {
  try {
    const beginTestData = await axios.put(`test/takeTest/${payload.currentTestId}`, payload).then(async (res) => {
      let testDataId = res.data?.data?.testData?.testMasterStudentMapId
      if (testDataId) {
        await thunk.dispatch(AllBookMarkAnswerAndUnAnswered(testDataId))
      }

      return res.data
    })
    return beginTestData
  } catch (error) {
    return error.response.data

  }

})

export const submitEachQuestion = createAsyncThunk('TestPage/SubmitEachQuestion', async (payload, thunk) => {
  try {
    let obj = {
      answerId: payload.answerId
    }
    const questionData = await axios.put(`/test/questionAnswer/${payload.id}`, obj).then(async (res) => {
      await thunk.dispatch(getSubmitQuestion(payload.testDataId))
      await thunk.dispatch(AllBookMarkAnswerAndUnAnswered(payload.testDataId))

      return res.data
    })
    return questionData
  } catch (error) {
  }

})

export const getSubmitQuestion = createAsyncThunk('TestPage/getSubmitQuestion', async (payload, thunk) => {
  try {

    const getData = await axios.get(`/test/continueTest/${payload}`).then((res) => {

      return res.data.data
    })
    return getData
  } catch (error) {
    message.error(error.response.data.message)
  }

})


// book mark question
export const setQuestionBookMark = createAsyncThunk('TestPage/setQuestionBookMark', async (payload, thunk) => {
  try {
    const getData = await axios.put(`test/addBookMarkQuestion/${payload.id}`).then(async (res) => {
      await thunk.dispatch(getSubmitQuestion(payload.testDataId))
      await thunk.dispatch(AllBookMarkAnswerAndUnAnswered(payload.testDataId))

      return res.data
    })
    return getData
  } catch (error) {
  }
})

// clear response

export const clearAnswerByQuestion = createAsyncThunk('TestPage/clearAnswerByQuestion', async (payload, thunk) => {
  try {
    // test/answerClearResponse/153
    const getData = await axios.put(`test/answerClearResponse/${payload.id}`).then(async (res) => {
      await thunk.dispatch(getSubmitQuestion(payload.testDataId))
      await thunk.dispatch(AllBookMarkAnswerAndUnAnswered(payload.testDataId))

      return res.data
    })
    return getData
  } catch (error) {
  }

})


// All Bookmark , Answered and UnAnswered

export const AllBookMarkAnswerAndUnAnswered = createAsyncThunk('TestPage/AllBookMarkAnswerAndUnAnswered', async (payload, thunk) => {
  try {
    const getData = await axios.get(`test/getBookMark/${payload}`).then(async (res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getData
  } catch (error) {
  }
})


//end test
export const EndTest = createAsyncThunk('TestPage/EndTest', async (payload, thunk) => {
  try {
    let obj;
    if (payload.id != null) {
      obj = {
        studentQuestionMapId: payload?.id,
        answerId: payload?.answerId
      }
    }
    else {
      obj = {}
    }

    const endTest = await axios.put(`test/submitQuestionAnswer/${payload.testDataId}`, obj).then(async (res) => {
      return res.data
    })
    return endTest

  } catch (error) {

  }

})


/* institution studend take test */
export const institutionStudentTakeTest = createAsyncThunk('TestPage/takeTest', async (payload, thunk) => {
  try {
    const takeTest = await axios.put(`test/institutional/student/takeTest/${payload.currentTestId}/assignId/${payload.assignId}?batchCode=${getEncryptedLocalStorage('batchCode')}`, payload).then(async (res) => {
      let testDataId = res.data?.data?.testData?.testMasterStudentMapId
      if (testDataId) {
        await thunk.dispatch(AllBookMarkAnswerAndUnAnsweredInsStud(testDataId))
      }
      return res.data
    })
    return takeTest
  } catch (error) {
    return error.response.data
  }
})

/* institution student book mark */
export const AllBookMarkAnswerAndUnAnsweredInsStud = createAsyncThunk('TestPage/AllBookMarkAnswerAndUnAnswered', async (payload, thunk) => {
  try {
    const getData = await axios.get(`test/getBookMarkInstitutionStudent/${payload}`).then(async (res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getData
  } catch (error) {
  }
})

// book mark question institution student
export const setQuestionBookMarkInsStudent = createAsyncThunk('TestPage/setQuestionBookMarkInsStudent', async (payload, thunk) => {
  try {
    const getData = await axios.put(`test/addBookMarkInsStudQuestion/${payload.id}`).then(async (res) => {
      await thunk.dispatch(getSubmitQuestionInsStud(payload.testDataId))
      await thunk.dispatch(AllBookMarkAnswerAndUnAnsweredInsStud(payload.testDataId))
      return res.data
    })
    return getData
  } catch (error) {
  }
})

/* continue test institution student */
export const getSubmitQuestionInsStud = createAsyncThunk('TestPage/getSubmitQuestionInsStud', async (payload, thunk) => {
  try {
    const getData = await axios.get(`/test/continueTestInsStudent/${payload}`).then((res) => {
      return res.data.data
    })
    return getData
  } catch (error) {
    message.error(error.response.data.message)
  }
})

/* question answer from institution student */
export const submitEachQuestionInsStud = createAsyncThunk('TestPage/submitEachQuestionInsStud', async (payload, thunk) => {
  try {
    let obj = {
      answerId: payload.answerId
    }
    const questionData = await axios.put(`/test/insStudQuestionAnswer/${payload.id}`, obj).then(async (res) => {
      await thunk.dispatch(getSubmitQuestionInsStud(payload.testDataId))
      await thunk.dispatch(AllBookMarkAnswerAndUnAnsweredInsStud(payload.testDataId))
      return res.data
    })
    return questionData
  } catch (error) {
  }
})

/* clear response institution student */
export const clearAnswerByQuestionInsStud = createAsyncThunk('TestPage/clearAnswerByQuestionInsStud', async (payload, thunk) => {
  try {
    const getData = await axios.put(`test/answerClearResponseInsStud/${payload.id}`).then(async (res) => {
      await thunk.dispatch(getSubmitQuestionInsStud(payload.testDataId))
      await thunk.dispatch(AllBookMarkAnswerAndUnAnsweredInsStud(payload.testDataId))
      return res.data
    })
    return getData
  } catch (error) {
  }
})


/* compelete institution test */
export const EndTestInstitutionStudent = createAsyncThunk('TestPage/EndTestInstitutionStudent', async (payload, thunk) => {
  try {
    let obj;
    if (payload.id != null) {
      obj = {
        studentQuestionMapId: payload?.id,
        answerId: payload?.answerId
      }
    }
    else {
      obj = {}
    }
    const endTest = await axios.put(`test/submitQuestionAnswerInsStud/${payload.testDataId}`, obj).then(async (res) => {
      return res.data
    })
    return endTest
  } catch (error) {
  }
})

/* take test in previous year */
export const takeTestPreviousYearExamNormal = createAsyncThunk('TestPage/takeTestPreviousYearExamNormal', async (payload, thunk) => {
  try {
    const beginTestData = await axios.put(`test/takeTestPreviousYearExam/${payload.currentTestId}`).then(async (res) => {
      let testDataId = res.data?.data?.testData?.testMasterStudentMapId
      if (testDataId) {
        await thunk.dispatch(AllBookMarkPreviousTest(testDataId))
      }
      return res.data
    })
    return beginTestData
  } catch (error) {
    return error.response.data
  }
})

export const takeTestPreviousYearExamInstitution = createAsyncThunk('TestPage/takeTestPreviousYearExamInstitution', async (payload, thunk) => {
  try {
    const beginTestData = await axios.put(`test/takeTestPreviousYearExamInstitution/${payload.currentTestId}/assignId/${payload.assignId}`).then(async (res) => {
      let testDataId = res.data?.data?.testData?.testMasterStudentMapId
      if (testDataId) {
        await thunk.dispatch(AllBookMarkPreviousTest(testDataId))
      }
      return res.data
    })
    return beginTestData
  } catch (error) {
    return error.response.data
  }
})

export const AllBookMarkPreviousTest = createAsyncThunk('TestPage/AllBookMarkPreviousTest', async (payload, thunk) => {
  try {
    const getData = await axios.get(`test/getBookMarkPreviousYearExam/${payload}`).then(async (res) => {
      if (res.data.success) {
        return res.data.data
      }
    })
    return getData
  } catch (error) {
  }
})

/* submit question each question */
export const submitPreviousYearEachQuestion = createAsyncThunk('TestPage/SubmitPreviousYearEachQuestion', async (payload, thunk) => {
  try {
    let obj = {
      answerId: payload.answerId
    }
    const questionData = await axios.put(`/test/previousYearQuestionAnswer/${payload.id}`, obj).then(async (res) => {
      await thunk.dispatch(getSubmitQuestionPreviousYearExam(payload.testDataId))
      await thunk.dispatch(AllBookMarkPreviousTest(payload.testDataId))

      return res.data
    })
    return questionData
  } catch (error) {
  }
})

export const getSubmitQuestionPreviousYearExam = createAsyncThunk('TestPage/getSubmitQuestionPreviousYearExam', async (payload, thunk) => {
  try {
    const getData = await axios.get(`/test/continueTestPreviousYearExam/${payload}`).then((res) => {
      return res.data.data
    })
    return getData
  } catch (error) {
    message.error(error.response.data.message)
  }
})

// book mark question
export const setQuestionBookMarkPreviousYearExam = createAsyncThunk('TestPage/setQuestionBookMarkPreviousYearExam', async (payload, thunk) => {
  try {
    const getData = await axios.put(`test/addBookMarkQuestionPreviousYearExam/${payload.id}`).then(async (res) => {
      await thunk.dispatch(getSubmitQuestionPreviousYearExam(payload.testDataId))
      await thunk.dispatch(AllBookMarkPreviousTest(payload.testDataId))
      return res.data
    })
    return getData
  } catch (error) {
  }
})

export const clearAnswerByQuestionPreviousYearExam = createAsyncThunk('TestPage/clearAnswerByQuestionPreviousYearExam', async (payload, thunk) => {
  try {
    const getData = await axios.put(`test/answerClearResponsePreviousYearExam/${payload.id}`).then(async (res) => {
      await thunk.dispatch(getSubmitQuestionPreviousYearExam(payload.testDataId))
      await thunk.dispatch(AllBookMarkPreviousTest(payload.testDataId))
      return res.data
    })
    return getData
  } catch (error) {
  }
})

//end test
export const EndTestPreviousYearExam = createAsyncThunk('TestPage/EndTestPreviousYearExam', async (payload, thunk) => {
  try {
    let obj;
    if (payload.id != null) {
      obj = {
        studentQuestionMapId: payload?.id,
        answerId: payload?.answerId
      }
    }
    else {
      obj = {}
    }
    const endTest = await axios.put(`test/submitQuestionAnswerPreviousYearExam/${payload.testDataId}`, obj).then(async (res) => {
      return res.data
    })
    return endTest
  } catch (error) {
  }
})




const TestPage = createSlice({
  name: 'TestPage',
  initialState: {
    isLoadingTestPage: false,
    questionData: [],
    testData: {},
    continueTestData: {},
    getBookMarkQuestion: [],
    bookMarkDetails: {}
  },
  reducers: {
    startLoader: (state, payload) => {
      state.isLoadingTestPage = true
    },
    stopLoader: (state, payload) => {
      state.isLoadingTestPage = false

    },
  },
  extraReducers: {
    [beginTest.pending]: (state) => {
      state.isLoadingTestPage = true
    },
    [beginTest.fulfilled]: (state, action) => {
      state.isLoadingTestPage = false
      state.questionData = action.payload?.data?.questionData
      state.testData = action.payload?.data?.testData

    },
    [beginTest.rejected]: (state) => {
      state.isLoadingTestPage = false

    },
    [submitEachQuestion.pending]: (state, action) => {
      //  state.isLoadingTestPage =  true

    },
    [submitEachQuestion.fulfilled]: (state, action) => {
      //  state.isLoadingTestPage =  false

    },
    [submitEachQuestion.rejected]: (state, action) => {
      //  state.isLoadingTestPage =  false

    },
    [getSubmitQuestion.pending]: (state) => {
      //  state.isLoadingTestPage =  true
    },
    [getSubmitQuestion.fulfilled]: (state, action) => {
      //  state.isLoadingTestPage =  false
      state.questionData = action.payload?.questionData
      state.testData = action.payload?.testData
      state.continueTestData = action.payload?.testDetails ? action.payload?.testDetails : {}

      //  state.getBookMarkQuestion = action.payload

    },
    [getSubmitQuestion.rejected]: (state) => {
      //  state.isLoadingTestPage =  false

    },
    [setQuestionBookMark.pending]: (state, action) => {
      //  state.isLoadingTestPage =  true

    },
    [setQuestionBookMark.fulfilled]: (state, action) => {
      //  state.isLoadingTestPage =  false

    },
    [setQuestionBookMark.rejected]: (state, action) => {
      //  state.isLoadingTestPage =  false

    },
    [clearAnswerByQuestion.pending]: (state, action) => {
      //  state.isLoadingTestPage =  true

    },
    [clearAnswerByQuestion.fulfilled]: (state, action) => {
      //  state.isLoadingTestPage =  false

    },
    [clearAnswerByQuestion.rejected]: (state, action) => {
      //  state.isLoadingTestPage =  false

    },

    [AllBookMarkAnswerAndUnAnswered.pending]: (state, action) => {
      //  state.isLoadingTestPage =  true

    },
    [AllBookMarkAnswerAndUnAnswered.fulfilled]: (state, action) => {
      //  state.isLoadingTestPage =  false

      state.getBookMarkQuestion = action.payload

      let stats = {
        answered: 0,
        marked: 0,
        unAnswered: 0
      }
      action.payload.forEach(ques => {
        if (ques['isAnswered']) {
          ++stats['answered']
        } else {
          ++stats['unAnswered']
        }

        if (ques['bookMarkQuestion']) {
          ++stats['marked']
        }
      });
      state.bookMarkDetails = stats

    },
    [AllBookMarkAnswerAndUnAnswered.rejected]: (state, action) => {
      //  state.isLoadingTestPage =  false
    },

    [EndTest.pending]: (state, action) => {
      state.isLoadingTestPage = true

    },
    [EndTest.fulfilled]: (state, action) => {
      state.isLoadingTestPage = false

    },
    [EndTest.rejected]: (state, action) => {
      state.isLoadingTestPage = false

    },
    /* institution student take test */
    [institutionStudentTakeTest.pending]: (state) => {
      state.isLoadingTestPage = true
    },
    [institutionStudentTakeTest.fulfilled]: (state, action) => {
      state.isLoadingTestPage = false
      state.questionData = action?.payload?.data?.questionData?.map((e) => {
        if (e.option.length && typeof e.option == 'string') {
          e.option = JSON.parse(e.option)
        }
        return e
      })
      state.testData = action.payload?.data?.testData

    },
    [institutionStudentTakeTest.rejected]: (state) => {
      state.isLoadingTestPage = false
    },

    /* institution student book mark */
    [AllBookMarkAnswerAndUnAnsweredInsStud.pending]: (state, action) => {
    },
    [AllBookMarkAnswerAndUnAnsweredInsStud.fulfilled]: (state, action) => {
      state.getBookMarkQuestion = action.payload
      let stats = {
        answered: 0,
        marked: 0,
        unAnswered: 0
      }
      action.payload.forEach(ques => {
        if (ques['isAnswered']) {
          ++stats['answered']
        } else {
          ++stats['unAnswered']
        }

        if (ques['bookMarkQuestion']) {
          ++stats['marked']
        }
      });
      state.bookMarkDetails = stats
    },
    [AllBookMarkAnswerAndUnAnsweredInsStud.rejected]: (state, action) => {
    },

    /* add institution student book mark */
    [setQuestionBookMarkInsStudent.pending]: (state, action) => {
    },
    [setQuestionBookMarkInsStudent.fulfilled]: (state, action) => {
    },
    [setQuestionBookMarkInsStudent.rejected]: (state, action) => {
    },

    /* get submit question institution student */
    [getSubmitQuestionInsStud.pending]: (state) => {
    },
    [getSubmitQuestionInsStud.fulfilled]: (state, action) => {
      state.questionData = action.payload?.questionData
      state.testData = action.payload?.testData
      state.continueTestData = action.payload?.testDetails ? action.payload?.testDetails : {}
    },
    [getSubmitQuestionInsStud.rejected]: (state) => {
    },

    /* question answer institution student */
    [submitEachQuestionInsStud.pending]: (state, action) => {
    },
    [submitEachQuestionInsStud.fulfilled]: (state, action) => {
    },
    [submitEachQuestionInsStud.rejected]: (state, action) => {
    },

    /* clear response institution student */
    [clearAnswerByQuestionInsStud.pending]: (state, action) => {
    },
    [clearAnswerByQuestionInsStud.fulfilled]: (state, action) => {
    },
    [clearAnswerByQuestionInsStud.rejected]: (state, action) => {
    },

    /* completed test from institution student */
    [EndTestInstitutionStudent.pending]: (state, action) => {
      state.isLoadingTestPage = true

    },
    [EndTestInstitutionStudent.fulfilled]: (state, action) => {
      state.isLoadingTestPage = false

    },
    [EndTestInstitutionStudent.rejected]: (state, action) => {
      state.isLoadingTestPage = false

    },

    /* previous year exam test */
    [takeTestPreviousYearExamNormal.pending]: (state) => {
      state.isLoadingTestPage = true
    },
    [takeTestPreviousYearExamNormal.fulfilled]: (state, action) => {
      state.isLoadingTestPage = false
      state.questionData = action.payload?.data?.questionData
      state.testData = action.payload?.data?.testData
    },
    [takeTestPreviousYearExamNormal.rejected]: (state) => {
      state.isLoadingTestPage = false
    },

    /* previous year exam test */
    [takeTestPreviousYearExamInstitution.pending]: (state) => {
      state.isLoadingTestPage = true
    },
    [takeTestPreviousYearExamInstitution.fulfilled]: (state, action) => {
      state.isLoadingTestPage = false
      state.questionData = action.payload?.data?.questionData
      state.testData = action.payload?.data?.testData
    },
    [takeTestPreviousYearExamInstitution.rejected]: (state) => {
      state.isLoadingTestPage = false
    },
    [AllBookMarkPreviousTest.pending]: (state, action) => {
      //  state.isLoadingTestPage =  true
    },
    [AllBookMarkPreviousTest.fulfilled]: (state, action) => {
      //  state.isLoadingTestPage =  false
      state.getBookMarkQuestion = action.payload
      let stats = {
        answered: 0,
        marked: 0,
        unAnswered: 0
      }
      action.payload.forEach(ques => {
        if (ques['isAnswered']) {
          ++stats['answered']
        } else {
          ++stats['unAnswered']
        }

        if (ques['bookMarkQuestion']) {
          ++stats['marked']
        }
      });
      state.bookMarkDetails = stats
    },
    [AllBookMarkPreviousTest.rejected]: (state, action) => {
    },
    [getSubmitQuestionPreviousYearExam.pending]: (state) => {
    },
    [getSubmitQuestionPreviousYearExam.fulfilled]: (state, action) => {
      state.questionData = action.payload?.questionData
      state.testData = action.payload?.testData
      state.continueTestData = action.payload?.testDetails ? action.payload?.testDetails : {}
    },
    [getSubmitQuestionPreviousYearExam.rejected]: (state) => {
    },
    [EndTestPreviousYearExam.pending]: (state, action) => {
      state.isLoadingTestPage = true
    },
    [EndTestPreviousYearExam.fulfilled]: (state, action) => {
      state.isLoadingTestPage = false
    },
    [EndTestPreviousYearExam.rejected]: (state, action) => {
      state.isLoadingTestPage = false
    },
  }
})
export const { startLoader, stopLoader } = TestPage.actions

export default TestPage.reducer