import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import '../styles/ClusterModal.scss';

function ClusterModal({ onClose, onConfirm, clusterData, defaultCluster }) {
    const [selectedCluster, setSelectedCluster] = useState(defaultCluster);
    const sliderRef = useRef(null);

    const handleClusterClick = (cluster) => {
        setSelectedCluster(cluster);
    };

    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        arrows: false,
        className: 'cluster-carousel',
        dots: false,
    };

    useEffect(() => {
        const index = clusterData.findIndex(c => c.id === defaultCluster);
        if (index !== -1 && sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    }, [defaultCluster]);

    return (
        <div className="modal-overlay cluster-modal">
            <div className="cluster-modal-content">

                <div className="cluster-modal-content-header">
                    {/* Title */}
                    <h2>Choose your cluster</h2>
                </div>

                <div className="cluster-modal-content-body">
                    {/* Dropdown */}
                    <select
                        value={selectedCluster}
                        onChange={(e) => {
                            const value = +e.target.value;
                            setSelectedCluster(value);
                            // Find index of selected cluster and scroll slider to that position
                            const index = clusterData.findIndex(c => c.id === value);
                            if (index !== -1 && sliderRef.current) {
                                sliderRef.current.slickGoTo(index);
                            }
                        }}
                        className="dropdown"
                    >
                        <option value="">Select Cluster</option>
                        {clusterData.map((cluster) => (
                            <option key={cluster.id} value={cluster.id}>{cluster.name}</option>
                        ))}
                    </select>

                    {/* Cluster Carousel */}
                    <Slider 
                        {...sliderSettings} 
                        className="cluster-carousel"
                        ref={sliderRef}
                        centerMode={true}
                        centerPadding="0px"
                        initialSlide={0}
                        infinite={true}
                        afterChange={(currentSlide) => {
                            // Update selected cluster when slider changes
                            if (clusterData[currentSlide]) {
                                setSelectedCluster(clusterData[currentSlide].id);
                                console.log('Selected cluster changed to:', clusterData[currentSlide].name);
                            }
                        }}
                    >
                        {clusterData.map((cluster) => {
                            return (
                                <div
                                    key={cluster.id}
                                    className={`cluster-card ${selectedCluster === cluster.id ? 'selected' : ''}`}
                                    onClick={() => {
                                        console.log('Cluster clicked:', cluster.name);
                                        handleClusterClick(cluster.id);
                                    }}
                                >
                                    <span>{cluster.name}</span>
                                </div>
                            );
                        })}
                    </Slider>
                </div>

                {/* Action Buttons */}
                <div className="action-buttons">
                    <button onClick={onClose} className="no-button">No</button>
                    <button onClick={() => onConfirm(selectedCluster)} className="yes-button">Yes</button>
                </div>
            </div>
        </div>
    );
}

export default ClusterModal;
