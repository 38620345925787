import React, {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAllClusterGroupByClusterId } from '../Slices/CoachmiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { Image, Tooltip } from "antd";
import BackIcon from '../assets/images/back_icon.svg'
import { addBreadCrumbData } from '../Slices/HomePageSlice';
import '../styles/AllClusterGroup.scss';
import Loading from '../Component/Loading';

const AllClusterGroups = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clusterId } = useParams();
    const { clusterGroupData,isLoading } = useSelector((state) => state.Coachmi);
    
    useEffect(()=>{
        dispatch(getAllClusterGroupByClusterId({ clusterId: clusterId, isLimitedData: false }))
    },[])

    const navigateToAllClusterGroup = (clusterGroup) => {
        navigate(`/home/coachmi/clusterGroupCourse/${clusterGroup?.clusterGroupMappingId}`,{ state: { slugId: clusterGroup?.slugId } });
    };

    const goBack = () => {
        navigate('/home/coachmi')
    };

  return (
  <>
   {
            isLoading ? (
                <div className='loading-container'>
                <Loading></Loading>
                </div>
            ) : (
    <div className="cluster-group-container">
       <div className='cluster-group-header'>
          <Image preview={false} src={BackIcon} className="back-icon"
          //  onClick={() => goBack()} 
          onClick={() => navigate(-1)}
           />
          <p>Exam wise Courses</p>
        </div>
    <div className="courses">
      {clusterGroupData?.map((clusterGroup, index) => (
        <div
          className="course-card"
          key={index}
          onClick={() => {navigateToAllClusterGroup(clusterGroup)}}
        >
               {
                clusterGroup.clusterGroupImage ? (
                  <Image
                  preview={false}
                  src={clusterGroup?.clusterGroupImage}
                  alt={clusterGroup?.clusterGroupName}
                  className="course-icon"
                />
                ) : (
                  <div className="course-icon course-icon-default"></div>
                )
              }
            <Tooltip title= {clusterGroup?.clusterGroupName}>
          <p className="clusterGroupName">
            {clusterGroup?.clusterGroupName}
          </p>
          </Tooltip>
        </div>
      ))}
    </div>
  </div>
 )
}
  </>
  
  )
}

export default AllClusterGroups