import { Button, Col, Drawer, Form, Image, Input, Radio, Row ,Upload, message} from 'antd'
import React, { useState } from 'react'
import cart from '../assets/images/shopping-cart-01.svg';
import profileOrderTest from '../assets/images/orderProfile.svg';
import purchaseProfile from '../assets/images/Purchase.svg';
import attemptProfile from '../assets/images/attempted testprofile.svg'
import profileImg from '../assets/images/Ellipse 169.svg'
import { Formik,useFormik } from 'formik';
import * as yup from 'yup';
import { GetUserDetails, changeUserName } from '../Slices/HomePageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updatePassword, updateProfile, uploadProfile } from '../Slices/ProfileSlice';
import { useNavigate } from 'react-router-dom'
import Loading from '../Component/Loading';
import {setEncryptedLocalStorage,getEncryptedLocalStorage} from "../utlis/HelperFunctions";
// import ImgCrop from 'antd-img-crop';

const Profile = () => {
  const [profileName ,setProfileName] = useState(getEncryptedLocalStorage("name")?.trim().charAt(0).toUpperCase())
  const [visible, setVisible] = useState(false)
  const [currentTitle, setCurrentTitle] = useState("Update Profile")
  const [resetTitle, setresetTitle] = useState("Update Password")
  const [resetVisible, setresetVisible] = useState(false)  
  const [profileImage, setProfileImage] = useState(null)  
  const [value, setValue] = useState();
  const { getUserDetails } = useSelector((state) => state.HomePage);
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.Profile);
  const [profileLoading, setProfileLoading] = useState(true);

  const openCloser = () => {
    setVisible(true);
  };
  const drawerCloser = () => {
    setVisible(false);
  };
  const openReset = () => {
    setresetVisible(true);
  };
  const closeReset = () => {
    setresetVisible(false);
    resetFormik.resetForm();
  };
  const init = {
    name: "",
    email: "",
    mobileNumber: "",
    gender: "",
  };
  const [initialValues, setInitialValues] = useState(init);
  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Name is Required")
      .matches(/^[a-zA-Z\s]*$/, "Name must be an alphabet characters"),
    email: yup
      .string()
      .email("Invalid Email Id")
      .required("Email is Required")
      .nullable(),
    mobileNumber: yup
      .string()
      .matches(/^[0-9\b]+$/, "Please Don't Enter Any Special Character")
      .required("Mobile Number is Required")
      .min(10, "Mobile Number Must Be Exactly 10")
      .max(10, "Mobile Number Must Be Exactly 10")
      .nullable(),
    gender: yup.string().required("A radio option is required"),
  });
  const onSubmit = async (values) => {
    const updatedValues = {
      ...values,
      mobileNumber: +values["mobileNumber"], // Ensure it's a number
      name: values["name"].trim(), // Trim whitespace from the name
    };

    try {
      const res = await dispatch(updateProfile(updatedValues)).unwrap();
      message.success(res?.message);
      setVisible(false);
      getAllDetails(); // Fetch updated details after successful update
    } catch (error) {
      // Handle any errors that occur during the dispatch
      console.error("Error updating profile:", error);

      // Display the error message from the server or a fallback message
      message.error(
        error?.message || "Profile update failed. Please try again."
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const onUpdate = (values) => {
    dispatch(updatePassword(values))
      .unwrap()
      .then((res) => {
        message.success(res.message);
        setresetVisible(false);
        resetFormik.resetForm();
      });
  };
  const resetFormik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      currentPassword: yup
        .string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      newPassword: yup
        .string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: yup
        .string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .oneOf([yup.ref("newPassword"), null], "Password doesn't match"),
    }),
    onSubmit: onUpdate,
  });

  const filehandleChange = (file) => {
    
    if (file.fileList.length) {
      if (
        file.file.type == "image/jpeg" ||
        file.file.type == "image/jpg" ||
        file.file.type == "image/png"
      ) {
        const size = file.file.size / 1024 / 1024;
        if (size > 2) {
          return message.error("Image must smaller than 2MB!");
        }
        setFile(file.fileList);
        // formik.values.examImage = file.fileList
        imgUpload(file.fileList[0].originFileObj);
      } else {
        message.error("please upload jpeg, jpg and png formats only ");
      }
    } else {
      setFile(file.fileList);
    }
  };

  const imgUpload = async (value) => {
    let data = new FormData();
    data.append("file", value); 
    try {
      const res = await dispatch(uploadProfile(data)).unwrap();
      console.log(res);
      message.success(res.message); 
      setProfileImage(res.url); 
      setFile([]); 
    } catch (error) {
      message.error(error?.message);
    }
  };

  useEffect(() => {
    getAllDetails();
  }, []);
  const getAllDetails = () => {
    dispatch(GetUserDetails()).then((res) => {
      localStorage.setItem("name", res.payload.name);
      let name = res.payload.name.trim().charAt(0).toUpperCase();
      setProfileName(name);
      setProfileImage(res.payload.key);
      setProfileLoading(false);
      dispatch(changeUserName(res.payload.name));
      formik.setValues(res.payload);
    });
  };

  return (
    <>
      {profileLoading ? (
        <Loading></Loading>
      ) : (
        <div className="profile-container">
          <div className="random-header">
            <div className="user-container">
              <div className="userProfile">
                <div
                  className="firstName"
                  style={{
                    backgroundImage: `url(${profileImage})`,
                    backgroundSize: "cover",
                  }}
                >
                  {/* <span >{profileName}</span> */}
                </div>
              </div>
              <div className="user-details">
                <p className="user-name">{getUserDetails?.name} </p>
                <p className="tag">Hope test was great today!</p>
              </div>
            </div>
            <div>
              <Button
                type="default"
                className="primary-submit-button"
                onClick={() => openCloser()}
              >
                Edit Profile
              </Button>
            </div>
          </div>
          <div className="quiz-content">
            <Row>
              {/* <Col span={8}> */}
              <Col xs={16} sm={12} md={12} lg={6}>
                <div
                  className="quiz-details-card"
                  onClick={() => navigate("/home/myCart")}
                >
                  <div className="card-image">
                    <Image preview={false} src={cart}></Image>
                  </div>
                  <div className="card-details">
                    <p className="card-header">My Cart</p>
                  </div>
                </div>
              </Col>
              {/* <Col span={8}> */}
              <Col xs={16} sm={12} md={12} lg={6}>
                <div
                  className="quiz-details-card"
                  onClick={() => navigate("/home/orderPage")}
                >
                  <div className="card-image">
                    <Image preview={false} src={profileOrderTest}></Image>
                  </div>
                  <div className="card-details">
                    <p className="card-header">Ordered Test</p>
                  </div>
                </div>
              </Col>
              {/* <Col span={8}> */}
              <Col xs={16} sm={12} md={12} lg={6}>
                <div
                  className="quiz-details-card"
                  onClick={() => navigate("/home/availableTest")}
                >
                  <div className="card-image">
                    <Image preview={false} src={purchaseProfile}></Image>
                  </div>
                  <div className="card-details">
                    <p className="card-header">Available Test</p>
                  </div>
                </div>
              </Col>
              {/* <Col span={8}> */}
              <Col xs={16} sm={12} md={12} lg={6}>
                <div
                  className="quiz-details-card"
                  onClick={() => navigate("/home/attemptedTest")}
                >
                  <div className="card-image">
                    <Image preview={false} src={attemptProfile}></Image>
                  </div>
                  <div className="card-details">
                    <p className="card-header">Attempted Test</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="personal-details-wrapper">
            <div className="personal-details">
              <div style={{ borderBottom: "1px solid #E0E0E0" }}>
                {" "}
                <p>Personal Details</p>{" "}
              </div>
              <div>
                <Row>
                  <Col className="personal-content" span={6}>
                    Name
                  </Col>
                  <Col
                    className="personal-answer"
                    style={{ textTransform: "capitalize" }}
                    span={18}
                  >
                    {getUserDetails.name ? getUserDetails.name : "-"}
                  </Col>
                  <Col className="personal-content" span={6}>
                    Email
                  </Col>
                  <Col className="personal-answer" span={18}>
                    {getUserDetails.email ? getUserDetails.email : "-"}
                  </Col>
                  <Col className="personal-content" span={6}>
                    Gender
                  </Col>
                  <Col className="personal-answer" span={18}>
                    {getUserDetails.gender ? getUserDetails.gender : "-"}
                  </Col>
                  <Col className="personal-content" span={6}>
                    Mobile
                  </Col>
                  <Col className="personal-answer" span={18}>
                    {getUserDetails.mobileNumber
                      ? getUserDetails.mobileNumber
                      : "-"}
                  </Col>
                  {/* <Col className='personal-content' span={6}>DoB</Col>
              <Col className='personal-answer' span={18}>4 Aug 1996</Col> */}
                  <Col className="personal-content" span={6}>
                    Password
                  </Col>
                  <Col className="personal-answer" span={18}>
                    {" "}
                    <p className="reset-btn" onClick={() => openReset()}>
                      Reset Password
                    </p>{" "}
                  </Col>
                </Row>
              </div>
            </div>
          </div>

          <Drawer
            visible={visible}
            width="518px"
            onClose={() => setVisible(false)}
            title={currentTitle}
            closable={false}
            className="profile-drawer"
            footer={
              <div className="footer-profile">
                <div className="footer-button">
                  <Button
                    className="cancel-Button primary-cancel-button"
                    type="primary"
                    onClick={drawerCloser}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-submit-button"
                    type="primary"
                    onClick={() => onSubmit(formik.values)}
                  >
                    Update{" "}
                  </Button>
                </div>
              </div>
            }
          >
            <div className="profile-img-wrapper">
              {/* <ImgCrop> */}
              <div
                className="avatar"
                style={{
                  backgroundImage: `url(${profileImage})`,
                  backgroundSize: "cover",
                }}
              >
                {/* {JSON.stringify(profileImage)} */}
                {!profileImage ? (
                  <p className="no-profile-image">No Profile</p>
                ) : null}
              </div>
              <div>
                <Upload
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={filehandleChange}
                  maxCount={1}
                >
                  <p className="upload-button">Upload</p>
                </Upload>
              </div>
              {/* <Upload className='ImageUpload'
                  listType="picture-card"
                  onChange={(file) => filehandleChange(file)}
                  beforeUpload={() => false}
                  maxCount={1}>
              <p className='upload-button' onClick={()=>imgUpload()}>Upload</p>
              </Upload> */}

              {/* </ImgCrop> */}
            </div>
            <div className="profile-form-wrapper">
              <Formik validateOnChange={false} validateOnBlur={false}>
                <Form>
                  <div style={{ marginTop: "20px" }}>
                    <div className="form-control">
                      <label htmlFor="name">
                        Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      ></Input>
                      {formik.touched.name && formik.errors.name ? (
                        <div className="error" style={{ color: "red" }}>
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-control">
                      <label htmlFor="email">
                        Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      ></Input>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error" style={{ color: "red" }}>
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-control">
                      <label htmlFor="mobileNumber">Mobile Number</label>
                      <Input
                        maxLength={10}
                        type="number"
                        id="mobileNumber"
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.mobileNumber}
                        disabled
                      ></Input>
                      {formik.touched.mobileNumber &&
                      formik.errors.mobileNumber ? (
                        <div className="error" style={{ color: "red" }}>
                          {formik.errors.mobileNumber}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-control">
                      <label htmlFor="gender">Gender</label>
                      <div className="gender-radio">
                        <Radio.Group value={formik.values.gender}>
                          <Radio
                            key={1}
                            value="Male"
                            onChange={() =>
                              formik.setFieldValue("gender", "Male")
                            }
                          >
                            Male
                          </Radio>
                          <Radio
                            key={2}
                            value="Female"
                            onChange={() =>
                              formik.setFieldValue("gender", "Female")
                            }
                          >
                            Female
                          </Radio>
                        </Radio.Group>
                        {formik.touched.gender && formik.errors.gender ? (
                          <div className="error" style={{ color: "red" }}>
                            {formik.errors.gender}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </Drawer>
          <Drawer
            visible={resetVisible}
            onClose={() => setresetVisible(false)}
            title={resetTitle}
            closable={false}
            width="518px"
            className="reset-drawer"
            footer={
              <div className="footer-profile">
                <div className="footer-button">
                  <Button
                    className="cancel-Button primary-cancel-button"
                    type="primary"
                    onClick={closeReset}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-submit-button"
                    type="primary"
                    disabled={!resetFormik.dirty || !resetFormik.isValid}
                    onClick={() => onUpdate(resetFormik.values)}
                  >
                    Update{" "}
                  </Button>
                </div>
              </div>
            }
          >
            <div>
              <Formik validateOnChange={false} validateOnBlur={false}>
                <Form>
                  <div className="form-control">
                    <label htmlFor="currentPassword">
                      Old Password<span style={{ color: "red" }}>*</span>
                    </label>
                    <Input.Password
                      type="password"
                      name="currentPassword"
                      placeholder="Old Password"
                      onBlur={resetFormik.handleBlur}
                      onChange={resetFormik.handleChange}
                      value={resetFormik.values.oldPass}
                    ></Input.Password>
                    {resetFormik.touched.currentPassword &&
                    resetFormik.errors.currentPassword ? (
                      <div className="error" style={{ color: "red" }}>
                        {resetFormik.errors.currentPassword}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-control">
                    <label htmlFor="newPassword">
                      New Password<span style={{ color: "red" }}>*</span>
                    </label>
                    <Input.Password
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      onBlur={resetFormik.handleBlur}
                      onChange={resetFormik.handleChange}
                      value={resetFormik.values.newPassword}
                    ></Input.Password>
                    {resetFormik.touched.newPassword &&
                    resetFormik.errors.newPassword ? (
                      <div className="error" style={{ color: "red" }}>
                        {resetFormik.errors.newPassword}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-control">
                    <label htmlFor="confirmPassword">
                      Confirm Password<span style={{ color: "red" }}>*</span>
                    </label>
                    <Input.Password
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onBlur={resetFormik.handleBlur}
                      onChange={resetFormik.handleChange}
                      value={resetFormik.values.confirmPassword}
                    ></Input.Password>
                    {resetFormik.touched.confirmPassword &&
                    resetFormik.errors.confirmPassword ? (
                      <div className="error" style={{ color: "red" }}>
                        {resetFormik.errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>
                </Form>
              </Formik>
            </div>
          </Drawer>
          {isLoading ? (
            <div className="loading-container">
              <Loading></Loading>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Profile;
