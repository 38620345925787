// Import necessary dependencies and styles
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import './index.css';
import './App.scss';
import 'antd/dist/antd.css';
import App from './App';
import store from './Store/store';
import reportWebVitals from './reportWebVitals';
import { getEncryptedLocalStorage } from "./utlis/HelperFunctions";

// Create a browser history object
const history = createBrowserHistory();

// Configure axios
// axios.defaults.baseURL = "http://localhost:4000/api/"
axios.defaults.baseURL = "https://staging.centum.academy/api/"
;
axios.defaults.headers.common['Content-Type'] = "application/json";

// Add request and response interceptors
axios.interceptors.request.use(
  (config) => {
    const token = getEncryptedLocalStorage("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      history.replace("/login");
    }
    return Promise.reject(error);
  }
);

// Create a root and render the React application
ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <React.StrictMode>
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </React.StrictMode>
  </Provider>
);

// Report web vitals for performance monitoring
reportWebVitals();
