import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getFreeClassesByClusterId, getContentVideoUrl } from '../Slices/CoachmiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/AllFreeClass.scss';
import { Image } from "antd";
import BackIcon from '../assets/images/back_icon.svg'
import { Tooltip } from 'antd';
import Loading from "../Component/Loading.js";
import { EContentType } from "../utlis/enum.js";
import { handleToastHelper } from '../utlis/HelperFunctions.js';
import { defaultContentThumbnail } from '../utlis/variable.js';

const AllFreeClass = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { freeClassData, isLoading } = useSelector((state) => state.Coachmi);
    const [videoUrls, setVideoUrls] = useState({});
    const inputRef = useRef(null);
    const videoRefs = useRef({});

    useEffect(() => {
        dispatch(getFreeClassesByClusterId({ clusterId: params.clusterId, isLimitedData: false }))
    }, [])

    const getEmbedUrl = (url) => {
        const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|[^\/\n\s]+\/(?:[^\n\s]+\/)?|watch\?v=)|youtu\.be\/)([^&?/\n\s]+)/);
        const videoId = videoIdMatch ? videoIdMatch[1] : null;
        return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
    };


    const getCloudFrontUrl = async (id, isOtherVideo = false) => {
        try {

            let key = isOtherVideo ? `${id + 'otherVideo'}` : `${id + 'contentVideo'}`;
            const response = await dispatch(getContentVideoUrl({ contentAttachmentId: id, type: isOtherVideo ? EContentType.OTHER_VIDEO_CONTENT : EContentType.COACHMI_CONTENT })).unwrap();
            const videoUrl = response['data'] || {};

            setVideoUrls((prevUrls) => ({
                ...prevUrls,
                [key]: videoUrl,
            }));

            if (videoRefs.current[key]) {
                videoRefs.current[key].load();
                videoRefs.current[key].play();
            }
        } catch (error) {
            handleToastHelper(error);
        }
    };

    const goBack = () => {
        navigate('/home/coachmi')
    }

    return (
        <>
            {
                isLoading ? (
                    <div className='loading-container'>
                        <Loading></Loading>
                    </div>
                ) : (
                    <div className="overAll-course-contaniner">
                        <div className='course-header'>
                            <Image preview={false} src={BackIcon}
                                className="back-icon"
                                //  onClick={() => goBack()} 
                                onClick={() => navigate(-1)}
                            />
                            <p>Free Class</p>
                        </div>

                        <div className="free-classes-container">
                            <div className="classes">
                                {freeClassData?.map((item, index) => {
                                    const videoUrl = videoUrls[item['attachmentMappingId']];
                                    const thumbnailUrl = item['thumbnailUrl'] || defaultContentThumbnail;
                                    return (
                                        <div className="class-card" key={index}>
                                            <div className="content-video-wrapper" onClick={() => getCloudFrontUrl(item['attachmentMappingId'])}>
                                                {videoUrl ? (
                                                    <video width="100%" height="180" controls
                                                        ref={(el) => (videoRefs.current[item['attachmentMappingId']] = el)}
                                                    >
                                                        <source src={videoUrls[item['attachmentMappingId']]} type="video/mp4" />
                                                    </video>
                                                )
                                                    : (
                                                        <div className={item['thumbnailUrl'] != null ? 'content-image-section' : ''}>
                                                            <Image
                                                                src={thumbnailUrl}
                                                                preview={false}
                                                                className="content-thumnail-wrapper"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="class-info">
                                                <div className="class-text">
                                                    <Tooltip placement="topLeft" title={item['freeClassName']}>
                                                        <p className="class-title">
                                                            {item['freeClassName']}
                                                        </p>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title={item['educatorNames']}>
                                                        <p className="class-instructor">By - {item['educatorNames']}</p>
                                                    </Tooltip>
                                                </div>
                                                <p className="class-type">Free Class</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    );
}

export default AllFreeClass;
