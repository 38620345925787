import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAllOtherVideosByClusterId, getContentVideoUrl } from '../Slices/CoachmiSlice';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/AllOtherVideos.scss';
import { Image } from "antd";
import BackIcon from '../assets/images/back_icon.svg';
import { Tooltip } from 'antd';
import Loading from '../Component/Loading';
import { handleToastHelper } from '../utlis/HelperFunctions';
import { EContentType } from '../utlis/enum';
import { defaultContentThumbnail } from '../utlis/variable';

const AllOtherVideos = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { otherVideosData, isLoading } = useSelector((state) => state.Coachmi);
    const [videoUrls, setVideoUrls] = useState({});
    const videoRefs = useRef({});

    useEffect(() => {
        dispatch(getAllOtherVideosByClusterId({ clusterId: params.clusterId, isLimitedData: false }))
    }, [])

    const goBack = () => {
        navigate('/home/coachmi')
    };

    const getCloudFrontUrl = async (id) => {
        try {
            const response = await dispatch(getContentVideoUrl({ contentAttachmentId: id, type: EContentType.OTHER_VIDEO_CONTENT })).unwrap();
            const videoUrl = response['data'] || {};

            setVideoUrls((prevUrls) => ({
                ...prevUrls,
                [id]: videoUrl,
            }));

            if (videoRefs.current[id]) {
                videoRefs.current[id].load();
                videoRefs.current[id].play();
            }
        } catch (error) {
            handleToastHelper(error);
        }
    };

    return (
        <>
            {
                isLoading ? (
                    <div className='loading-container'>
                        <Loading></Loading>
                    </div>
                ) : (
                    <div className="overAll-course-contaniner">
                        <div className='course-header'>
                            <Image preview={false} src={BackIcon} 
                            className="back-icon"
                            //  onClick={() => goBack()}
                            onClick={() => navigate(-1)}
                             />
                            <p>Other Videos</p>
                        </div>

                        <div className="free-classes-container">
                            <div className="classes">
                                {otherVideosData?.map((item, index) => {
                                    const videoUrl = videoUrls[item['attachmentMappingId']];
                                    const thumbnailUrl = item['thumbnailUrl'] || defaultContentThumbnail;
                                    return (
                                        <div className="class-card" key={index}>
                                            <div className="content-video-wrapper" onClick={() => getCloudFrontUrl(item['attachmentMappingId'], true)}>
                                                {videoUrl ? (
                                                    <video width="100%" height="180" controls
                                                        ref={(el) => (videoRefs.current[item['attachmentMappingId']] = el)}
                                                    >
                                                        <source src={videoUrls[item['attachmentMappingId']]} type="video/mp4" />
                                                    </video>
                                                )
                                                    : (
                                                        <div className={item['thumbnailUrl'] != null ? 'content-image-section' : ''}>
                                                            <Image
                                                                src={thumbnailUrl}
                                                                preview={false}
                                                                className="content-thumnail-wrapper"
                                                            />
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="class-info">
                                                <div className="class-text">
                                                    <Tooltip placement="topLeft" title={item['contentName']}>
                                                        <p className="class-title">
                                                            {item['contentName']}
                                                        </p>
                                                    </Tooltip>
                                                    <Tooltip placement="topLeft" title={item['educators']?.map(e => e['name']).join(', ')}>
                                                        <p className="class-instructor">
                                                            By - {item['educators']?.map(e => e['name']).join(', ')}
                                                        </p>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    );
}

export default AllOtherVideos;
