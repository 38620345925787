import { message as Toast } from 'antd';
import ComponentLoading from '../Component/ComponentLoading';
import { Modal, Button } from 'antd';
import { Image, Tooltip } from "antd";
import CourseImg from '../assets/images/courseEmtImg.svg';
import DownloadImg from '../assets/images/downloadEmtImg.svg';
import CryptoJS from "crypto-js";


export const getLeastMonthsSubscriptionHelper = (subscriptions) => {
    if (!subscriptions || subscriptions?.length === 0) {
        return null;
    }
    return subscriptions?.reduce((min, current) => {
        return current.numberOfMonths < min.numberOfMonths ? current : min;
    });
};

export const PricingNoDataContentHelper = () => {
    return (
        <p>No Plan Pricing Data</p>
    );
};

export const setLocalStorageItemHelper = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
    }
};

export const getLocalStorageItemHelper = (key) => {
    try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    } catch (error) {
        return null;
    }
};

export const handleToastHelper = ({ success, message }, errorToastOnly = false) => {
    if (!success) {
        return Toast.error(message);
    };

    if (!errorToastOnly) {
        return Toast.success(message);
    };
};

export const renderFollowStatusFunction = (educator, paramFunction, isLoading) => {

    const className = educator['following'] ? 'follow following' : 'follow';
    const content = educator['following'] ? 'Following' : 'Follow';

    return (
        <>
        <span className={className}
            onClick={() => paramFunction(educator)}
        >
            {isLoading ? <ComponentLoading /> : content}
        </span>
        </>
    )
};

export const renderVerticalLineHelper = (condition) => {
    return condition && <p className="vertical-line"></p>;
};
export const CoursePricingHelper = (course) => {
    return (
        <div className="course-pricing">
            {
                course['coursePrice'] && (
                    <span className="price">₹ {course['coursePrice']}</span>
                )
            }

            {
                course['actualPrice'] &&
                course['coursePrice'] &&
                course['coursePrice'] !== course['actualPrice'] && (
                    <span className="price original-price">₹ {course['actualPrice']}</span>
                )
            }
        </div>
    );
};

export const SubTopicNoDataContentHelper = () => {
    return (
        <p>No Sub topic Data</p>
    );
};

export const NoDataCourseHelper = () => {
    return (
        <Image preview={false} src={CourseImg} className="course-image"/>
    );
};

export const NoDataDownloadsHelper = () => {
    return (
        <Image preview={false} src={DownloadImg} className="course-image"/>
    );
};

export const replaceRouteParamsHelper = (route, params) => {
    return route.replace(/\$\{(\w+)\}/g, (_, key) => params[key] || '');
};


const secretKey = process.env.REACT_APP_YOUR_SECRET_KEY;
if (!secretKey) {
    console.error("Secret key is undefined. Please check your .env file.");
}

export const setEncryptedLocalStorage = (key, value) => {
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
    localStorage.setItem(key, encryptedValue);
};

export const getEncryptedLocalStorage = (key) => {

    try {
        const encryptedValue = localStorage.getItem(key);
        if (!encryptedValue)  return null;
        
            const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, secretKey).toString(CryptoJS.enc.Utf8);
            return decryptedValue ? JSON.parse(decryptedValue) : null;
    } catch (error) {
        console.error("Error decrypting or parsing local storage data:", error);
        return null;

    }
};


