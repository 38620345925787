import { useState,useEffect } from 'react';
import { Formik, useFormik } from 'formik';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Button,Drawer,Image,Input,Spin} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {otpVerify,resetOtp,otpclose,passwordOpen} from '../Slices/signUpSlice'
import centumLogo from '../assets/images/Centum_App_logo.svg';
import '../styles/otpPage.scss';
import '../styles/globalMediaQuery.scss';
import * as yup from 'yup';
import Loading from '../Component/Loading';
import {getEncryptedLocalStorage} from "../utlis/HelperFunctions"


function EnterOtp(){
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const {mobileNumber} = useSelector((state)=>state.signUpSlice)
  const antIcon = (<LoadingOutlined style={{fontSize: 24,}}spin/>);
  const {isLoading}=useSelector((state)=>state.signUpSlice)
  const {otpVisible}=useSelector((state)=>state.signUpSlice)
  const [visible,setVisible]=useState();
  const [disabledButton,setDisabled]=useState(false);
  const [otp,setOtp]=useState();



  function resendOtp(){
    var mobileNumber=getEncryptedLocalStorage("mobileNumber")
    let data={
      mobileNumber:Number(mobileNumber),
      countryCallingCode:91
    }
  dispatch(resetOtp(data))
  }

  function onClose(){
    dispatch(otpclose())
    formik.resetForm()
  }

  function onSubmit(values){
    dispatch(otpclose());
    dispatch(otpVerify(values.otp)).unwrap().then((res)=>{
      // localStorage.setItem('uuidToken',res.uuidToken)
      if(res.isVerified==true){
      //  dispatch(passwordOpen())
      navigate(`/password/${res.uuidToken}`)
      }
    })

    formik.resetForm()
  }
  const initialValues= { otp: ''}

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema:yup.object({
      otp:yup.string().required("Otp is required")
    })
  })
  useEffect(()=>{
    if(!mobileNumber){
      navigate('/login')
    }
  },[])

  return(
    <>
    <div>
          <div className='otp-container user-onboarding-container'>
            <div className='otpImage user-onboarding-image'></div>
            <div className='otp-wrapper user-onboarding-wrapper'>
            <div className='otp-content'>
                <div ><Image height={70} preview={false} src={centumLogo}></Image></div>
                <div>
                  <h2 className='header'>Enter OTP</h2>
                  <p className='sub-header'>We've sent an OTP to your registered Mobile Number</p>
                  <p className='sub-header'>XXXXXX{mobileNumber}</p>
                </div>
              </div>
              <div className='otp-form'>
                <div className='form-wrapper'>
                <div className='form-control' >
                  <Input placeholder='Enter Your OTP' type='number' id='otp' name='otp' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.otp}></Input>
                  {formik.touched.otp && formik.errors.otp ? <div className='error'>{formik.errors.otp}</div> : null}
                </div>
                <div className='form-control'>
                 <Button className='login-button'  type='primary' disabled={!formik.dirty || !formik.isValid}  onClick={()=>onSubmit(formik.values)}>Verify OTP</Button>

                </div>
                <div>
                <p  className='navigate'> Back To<a onClick={()=>navigate("/login")}> Login </a></p>

                </div>
                </div>

              </div>

            </div>

          </div>
    </div>
      {isLoading ? <div className='loading-container'>

      <Loading></Loading>

      </div> : null
      }

    </>
  )
}

export default EnterOtp;
