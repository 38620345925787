import React, { useEffect, useState } from "react";
import '../styles/SubscriptionPlan.scss';
import { getSubscriptionPlan, addCoachmiCart, removesubscription } from "../Slices/CoachmiSlice";
import { useDispatch, useSelector } from "react-redux";
import PointsIcon from '../assets/images/PointsIcon.svg';
import CoupanIcon from '../assets/images/coupanIcon.svg';
import arrow from '../assets/images/contentAarrow.svg'
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Button, Image } from "antd";
import BackIcon from '../assets/images/back_icon.svg';
import Loading from "../Component/Loading";
import useBackNavigation from '../hooks/useBackNavigation';
import NoDataComponent from "../utlis/NoDataComponent.js"
import { PricingNoDataContentHelper, getLocalStorageItemHelper, handleToastHelper } from "../utlis/HelperFunctions.js";

const SubscriptionPlan = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = useBackNavigation();
  const { id } = useParams();
  const queryParams = new URLSearchParams(location?.search);
  const subscriptionType = queryParams.get('type');

  const { isLoading, subscriptionData } = useSelector((state) => state.Coachmi);
  const { getUserDetails } = useSelector((state) => state.HomePage)

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [paymentModuleId, setPaymentModuleId] = useState(null);
  const [pricingId, setPricingId] = useState(null);
  const [alreadySelected, setAlreadySelected] = useState(0);
  const [points] = useState(260.50);

  const handlePlanSelection = (plan) => {
    setSelectedPlan(plan?.period);
    setTotalAmount(plan?.price);
    setPricingId(plan?.id);
    setPaymentModuleId(plan?.paymentModuleId);
    setAlreadySelected(plan?.alreadySelected);
  };

  const addToCart = async () => {
      try {
        const response = await dispatch(addCoachmiCart({ paymentModuleId, pricingId })).unwrap();
        handleToastHelper(response);
        if (response['success']) {
          dispatch(removesubscription());
          dispatch(getSubscriptionPlan({ type: subscriptionType, id }));
        }
      } catch (error) {
        handleToastHelper(error);
      }
  };

  useEffect(() => {
    dispatch(removesubscription());
    dispatch(getSubscriptionPlan({ type: subscriptionType, id }));
  }, []);

  useEffect(() => {
    if (subscriptionData) {
      const { price = null, period = null, id = 0, paymentModuleId = 0, alreadySelected = 0 } = subscriptionData?.planPricing?.[0] || {};
      setSelectedPlan(period);
      setTotalAmount(price);
      setPaymentModuleId(paymentModuleId);
      setPricingId(id);
      setAlreadySelected(alreadySelected);
    }
  }, [subscriptionData])

  return (
    <>
    {subscriptionData?.planPricing?.length > 0 ? (
      <div className="app-container">
        <div className="content-header">
          <Image
            preview={false}
            src={BackIcon}
            className="back-icon"
            // onClick={() => goBack(getLocalStorageItemHelper('subscriptionGoBackPath'))}
            onClick={() => navigate(-1)}
          />
          <p>Subscription Plan</p>
        </div>

        <div className="course-title">
          <p>{subscriptionData?.name || '-'}</p>
        </div>

          <div className="content">
          <div className="price-plan-container">
            {subscriptionData?.planPricing?.map(plan => (
              <div
                key={plan?.id}
                className={`plan ${selectedPlan === plan?.period || plan?.alreadySelected ? 'plan-selected' : ''}`}
                onClick={() => !plan?.alreadySelected && handlePlanSelection(plan)}>
                <div className="subscription-duration">{plan?.period}</div>
                <div className="subscription">
                  <div className="subscription-price">₹{plan?.price}</div>
                  <div className="subscription-permonth">₹{ (Number(plan?.price) / Number(plan?.numberOfMonths)).toFixed() }/ month</div>
                </div>
              </div>
            ))}
          </div>
          <div className="summary-section">
            <div className="user-details">
              <div className="sub-plan">
                <div className="validity">{selectedPlan} Validity</div>
                <div className="user-details-subscription">
                  <div className="price">₹{totalAmount}</div>
                  {/* <div className="discount">50% OFF</div> */}
                </div>
              </div>
              <div className="details">
                <div className="user-name">Name:<span>{getUserDetails?.name}</span> </div>
                <div className="user-name">Mobile Number: <span>+91{getUserDetails?.mobileNumber}</span></div>
                <div className="user-name">Email:<span>{getUserDetails?.email}</span></div>
              </div>
            </div>
            {/* <div className="discount-section">
                <div className="points">
                  <img src={PointsIcon} alt="" className="image-class" />
                  Use Points: {points}
                  <input
                    className="input-filed"
                    type="checkbox"
                    onChange={e => setDiscount(e.target.checked ? points : 0)}
                  />
                </div>
              <div className="coupon">
                <img src={CoupanIcon} alt="" className="image-class" />
                <p>Available Coupon</p>
                <img src={arrow} alt="" className="coupan-image" />
              </div>
            </div> */}
            <div className="payment-summary">
              <div className="to-be-paid">To be Paid:<span>₹{totalAmount - discount}</span></div>
                <Button className={`pay-now ${alreadySelected ? 'primary-disabled-button' : ''}`} onClick={!alreadySelected ? addToCart : undefined} >Add Cart</Button>
            </div>
          </div>
        </div>
      </div>
      ) : (
        <NoDataComponent content={PricingNoDataContentHelper()} handleGoBack={goBack} goBackVisible={false} />
      )}
    {
      isLoading && <Loading />  
    }
    </>

  );
};

export default SubscriptionPlan;