import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAllMyCourse } from '../Slices/CoachmiSlice.js';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TamilIcon } from '../assets/images/tamilIcon.svg'
import { ReactComponent as EnglishIcon } from '../assets/images/englishIcon.svg'
import '../styles/AllCourses.scss'
import { Image, Tooltip } from "antd";
import BackIcon from '../assets/images/back_icon.svg'
import Loading from "../Component/Loading.js";
import { NoDataCourseHelper, renderVerticalLineHelper, replaceRouteParamsHelper } from '../utlis/HelperFunctions.js'
import NoDataComponent from '../utlis/NoDataComponent.js';

const AllMyCourse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState([]);
  const { getAllMyCourseDetails, isLoading } = useSelector((state) => state.Coachmi);

  useEffect(() => {
    dispatch(getAllMyCourse());
  }, []);

  useEffect(() => {
    setCourseDetails(getAllMyCourseDetails);
  }, [getAllMyCourseDetails]);

  useEffect(() => {
    if (Array.isArray(courseDetails) && courseDetails?.length > 0) {

      setCourseDetails((prev) => {
        return prev?.map((course) => {
          const { route, ...rest } = course;
          return {
            ...course,
            route: replaceRouteParamsHelper(route, rest),
          };
        });
      });

    }
  }, [getAllMyCourseDetails]);  

  const goBack = () => {
    navigate('/home/coachmi')
  };

  return (
    <>
      {
        isLoading ? (
          <div className='loading-container'>
            <Loading></Loading>
          </div>
        ) : (
          <div className="overAll-course-contaniner">
            <div className='course-header'>
              <Image preview={false} src={BackIcon} 
                className="back-icon"
              //  onClick={() => goBack()}
              onClick={() => navigate(-1)}
               />
              <p>My Courses</p>
            </div>

            {
              courseDetails?.length > 0 ? (
                <div className="courses-container">
                  <div className="courses-grid">
                    {courseDetails?.map((course, index) => {

                      const hasDuration = Boolean(course['duration']);
                      const hasTests = course['testCount'] > 0;
                      const hasDocuments = course['documentCount'] > 0;

                      return (
                        <div className="course-card" key={index} onClick={() => navigate(course['route'])}>
                          {
                            course['thumbnailUrl'] ? (
                              <img
                                src={course['thumbnailUrl']}
                                alt=''
                                className="course-image"
                              />
                            ) : (
                              <div className="course-image course-image-default"></div>
                            )
                          }
                          <div className="course-tag">
                            <p className="course-type">Recorded</p>
                            {course['languageId'] == 1 ? (
                              <p>
                                <TamilIcon />
                              </p>
                            ) : (
                              <p>
                                <EnglishIcon />
                              </p>
                            )}
                          </div>
                          <Tooltip title={course['clusterGroupName']}>
                            <p className="course-clusterGroup">{course['clusterGroupName']}</p>
                          </Tooltip>
                          <Tooltip title={course['courseName']}>
                            <p className="course-title">{course['courseName']}</p>
                          </Tooltip>
                          <div className="course-details">

                            {hasDuration && (
                              <>
                                <p className="courseHours">{course['duration']}</p>
                                {renderVerticalLineHelper(hasTests || hasDocuments)}
                              </>
                            )}

                            {hasTests && (
                              <>
                                <p className="courseTests">{course['testCount']} Test Series</p>
                                {renderVerticalLineHelper(hasDocuments)}
                              </>
                            )}

                            {hasDocuments && (
                              <p className="courseDocs">{course['documentCount']} Documents</p>
                            )}
                          </div>
                        </div>
                      )
                    }
                    )}
                  </div>
                </div>
              ) : (
                <div className="no-data-found-wrapper course-wrapper">
                   <NoDataComponent content={NoDataCourseHelper()} />
              </div>
              )
            }
          </div>
        )
      }

    </>
  );
};

export default AllMyCourse;
