import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Button, Image, Input, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, institutionStudentLogin } from '../Slices/signUpSlice';
import * as yup from "yup";
import centumLogo from '../assets/images/Centum_App_logo.svg';
import '../styles/login.scss';
import '../styles/globalMediaQuery.scss';
import Loading from '../Component/Loading';
import { Tabs } from 'antd';
const { TabPane } = Tabs;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.signUpSlice)
  const [tabStatus, setTabStatus] = useState("1")

  async function onSubmit(values) {
    values = {
      countryCallingCode: 91,
      mobileNumber: Number(values.mobileNumber),
      password: values.password,
      platform: "Web",
      version: ""
    }
    await dispatch(login(values)).unwrap().then((res) => {
      if (res?.status == true) {
        navigate('/home')
      }
    });
    formik.resetForm();
    institutionStudentFormik.resetForm();
  }

  const validationStudent = yup.object({
    mobileNumber: yup.string().required("MobileNumber is Required").max(10, 'Mobile number must be 10 digits').matches(
      /^\d{10}$/,
      'Mobile number must be 10 digits'),
    password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters')
  })
  const validationInstitutionStudent = yup.object({
    batchCode: yup.string().required("Batch Code is Required"),
    email: yup.string().email('Invalid Email Id').required("Email is Required"),
    password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters')
  })

  const signupDrawer = () => {
    navigate('/signUp')
  }
  const forgetPassword = () => {
    navigate('/forgotPassword')
  }
  // const forgetPasswordInstitution = () => {
  //   navigate('/forgotPasswordInsStud')
  // }

  const formik = useFormik({
    initialValues: {
      password: '',
      mobileNumber: ''
    },
    validationSchema: validationStudent,
  })

  const institutionStudentFormik = useFormik({
    initialValues: {
      batchCode: '',
      email: '',
      password: ''
    },
    validationSchema: validationInstitutionStudent,
  })

 async function onSubmitInstitute(values) {
    values = {
      batchCode: values.batchCode.toString(),
      email: values.email,
      password: values.password,
      platform: "Web",
      version: ""
    };
   await dispatch(institutionStudentLogin(values))
      .unwrap()
      .then((res) => {
        if (res.status == true) {
          navigate('/institutionHome');
        }
      });
    formik.resetForm();
    institutionStudentFormik.resetForm();
  }

  const onTabChange = (data) => {
    setTabStatus(data)
  };

  const handleKeyDown = (event, isInstitutionLogin = false) => {    
    if (event.key === 'Enter') {
        event.preventDefault(); 

      if (formik?.isValid && !isInstitutionLogin) {
        onSubmit(formik.values);
      } else if(institutionStudentFormik?.isValid && isInstitutionLogin) {
        onSubmitInstitute(institutionStudentFormik.values);
      }
    }
  };


  return (
    <>
      <div>
        <div className='login-container user-onboarding-container'>
          <div className='loginImage user-onboarding-image'>
          </div>
          <div className='login-wrapper user-onboarding-wrapper'>
            <div className='login-content'>
              <div ><Image height={70} preview={false} src={centumLogo}></Image></div>
              <div>
                <h2 className='header'>Welcome Back</h2>
                <p className='sub-header'>Continue with your Mobile Number</p>
              </div>
            </div>
            <div className='login-form'>
              <Tabs activeKey={tabStatus} onChange={onTabChange}>
                <TabPane tab="Individual" key="1">
                <Form onKeyDown={(e) => handleKeyDown(e, false)}>
                  <div className='form-wrapper'>
                    <div className='form-control' >
                      <label>Mobile Number</label>
                      <Input id='mobileNumber' placeholder='Enter a Mobile Number' type='number' name='mobileNumber' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNumber}></Input>
                      {formik.touched.mobileNumber && formik.errors.mobileNumber ? <div className='error'>{formik.errors.mobileNumber}</div> : null}
                    </div>
                    <div className='form-control'>
                      <label >Password</label>
                      <Input.Password type='password' placeholder='Enter a Password' id='password' name='password' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.password}></Input.Password>
                      <div >
                        {formik.touched.password && formik.errors.password ? <div className='error'>{formik.errors.password}</div> : null}
                      </div>
                    </div>
                    <div className='form-control'>
                      <p className='forgot-text' ><a className='forgot-link' onClick={() => forgetPassword()}>Forgot Password</a></p>
                    </div>
                    <div className='form-control'>
                      <Button className='login-button' type='primary' disabled={!formik.dirty || !formik.isValid} onClick={() => onSubmit(formik.values)}>LOGIN</Button>
                    </div>
                    <div>
                      <p className='signIn-option'>Don’t  Have an Account? <a onClick={() => signupDrawer()}>Signup</a></p>
                    </div>
                  </div>
                </Form>
                </TabPane>

                <TabPane tab="Institution" key="2">
                <Form onKeyDown={(e) => handleKeyDown(e, true)}>
                  <div className='form-wrapper'>
                    <div className='form-control institution-form-control' >
                      <label>Batch Code</label>
                      <Input id='batchCode' placeholder='Enter a Batch Code' name='batchCode' onChange={institutionStudentFormik.handleChange} onBlur={institutionStudentFormik.handleBlur} value={institutionStudentFormik.values.batchCode}></Input>
                      {institutionStudentFormik.touched.batchCode && institutionStudentFormik.errors.batchCode ? <div className='error'>{institutionStudentFormik.errors.batchCode}</div> : null}
                    </div>
                    <div className='form-control institution-form-control' >
                      <label>Student email</label>
                      <Input id='email' placeholder='Enter a Student Email' name='email' onChange={institutionStudentFormik.handleChange} onBlur={institutionStudentFormik.handleBlur} value={institutionStudentFormik.values.email}></Input>
                      {institutionStudentFormik.touched.email && institutionStudentFormik.errors.email ? <div className='error'>{institutionStudentFormik.errors.email}</div> : null}
                    </div>
                    <div className='form-control institution-form-control'>
                      <label >Password</label>
                      <Input.Password type='password' placeholder='Enter a Password' id='password' name='password' onChange={institutionStudentFormik.handleChange} onBlur={institutionStudentFormik.handleBlur} value={institutionStudentFormik.values.password}></Input.Password>
                      <div >
                        {institutionStudentFormik.touched.password && institutionStudentFormik.errors.password ? <div className='error'>{institutionStudentFormik.errors.password}</div> : null}
                      </div>
                    </div>
                    {/* <div className='form-control'>
                      <p className='forgot-text' ><a className='forgot-link' onClick={() => forgetPasswordInstitution()}>Forgot Password</a></p>
                    </div> */}
                    <div className='form-control'>
                      <Button className='login-button' type='primary' disabled={!institutionStudentFormik.dirty || !institutionStudentFormik.isValid} onClick={() => onSubmitInstitute(institutionStudentFormik.values)}>LOGIN</Button>
                    </div>
                  </div>
                </Form>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>

      </div>
      {isLoading ? <div className='loading-container'>

        <Loading></Loading>

      </div> : null
      }
    </>
  )
}

export default Login