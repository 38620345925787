import React, { useState, useRef, useEffect } from 'react';
import { Input, List, Empty } from 'antd';
import searchIcon from "../assets/images/Search_light.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getCoachmiGloabalSearchValue, resetCoachmiGlobalSearchValue } from "../Slices/CoachmiSlice";
import { Link } from 'react-router-dom';
import { useDebouncedCallback } from "use-debounce";
import { replaceRouteParamsHelper } from '../utlis/HelperFunctions';

const SearchBar = ({ clusterId }) => {

  const { coachmiGlobalSearchValue } = useSelector((state)=> state.Coachmi);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [transformedSuggestions, setTransformedSuggestions] = useState([]);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setSearchQuery(e.target.value);
    setLoading(true); 
  };

  const fetchSuggestions = useDebouncedCallback((query) => {
    let payload = {
      clusterId : clusterId,
      searchValue : query
    };
    dispatch(getCoachmiGloabalSearchValue(payload)).finally(() => setLoading(false));
  }, 1000);

  useEffect(() => {
    setSuggestions(coachmiGlobalSearchValue);
  }, [coachmiGlobalSearchValue]);  

  useEffect(() => {
    if (Array.isArray(suggestions) && suggestions?.length > 0) {
  
      const grouped = suggestions.reduce((acc, item) => {
        if (!acc[item['type']]) {
          acc[item.type] = [];
        }
  
        const newItem = { ...item };
        newItem.route = replaceRouteParamsHelper(newItem['route'], newItem);
  
        acc[item['type']].push(newItem);
        return acc;
      }, {});
  
      const groupedAndTransformed = Object.entries(grouped).flatMap(([type, items]) => [
        { type, isHeader: true },
        ...items.map(item => ({ ...item, type }))
      ]);
  
      setTransformedSuggestions(groupedAndTransformed);
    }
  }, [suggestions]);

  useEffect(() => {
    if (searchQuery?.trim()?.length > 0) {
      resetSearchResult();
      fetchSuggestions(searchQuery);
    } else {
      resetSearchResult();
    }
  }, [searchQuery]);  
  
  const resetSearchResult = () => {
    setSuggestions([]);
    setTransformedSuggestions([]);
    dispatch(resetCoachmiGlobalSearchValue());
  };

  return (
    <>
      <Input
        size="large"
        placeholder="Search"
        autoComplete="off"
        onChange={handleOnChange}
        value={searchQuery}
        prefix={<img src={searchIcon} alt="Search icon" />}
        ref={inputRef}
      />
      {searchQuery && transformedSuggestions?.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={transformedSuggestions}
          renderItem={(item) =>
            item.isHeader ? (
              <List.Item>
                <p className="data-type">{item['type']}</p>
              </List.Item>
            ) : (
              <Link to={item['route']}>
              <List.Item>
                <List.Item.Meta title={item['name']} />
              </List.Item>
              </Link>
            )
          }
        />
      ) : searchQuery && !loading ? (
        <List itemLayout="horizontal">
          <List.Item className="global-search-no-data-found">
            <Empty description="No data found" />
          </List.Item>
        </List>
      ) : null}
    </>
  );
};

export default SearchBar;